<app-header [isLogin]="isLogin"></app-header>

<kendo-drawer-container>
  <kendo-drawer
    #drawer
    [items]="items"
    [mini]="true"
    [(expanded)]="expanded"
    mode="push"
    (select)="onSelect($event)">
    <ng-template kendoDrawerItemTemplate let-item>
      <div class="menu-item-container" [ngClass]="{'selected': itemIsSelected(item.path)}">
        <div style="text-align: center;" [ngClass]="{'lrv-fleet-margin': item.icon === 'lrv-fleet'}" id="menu">
          <span class="{{item.icon}}"></span>
        </div>
        <div class="item-text">{{ item.text }}</div>
      </div>
    </ng-template>
  </kendo-drawer>

  <kendo-drawer-content>
    <router-outlet></router-outlet>
  </kendo-drawer-content>
</kendo-drawer-container>

