import {Component, OnDestroy, OnInit} from '@angular/core';
import { ProblematikInterface } from "../../../models/problematik.interface";
import {DatenLoggerInterface, FlotteInterface, FlotteResultInterface} from "./flotte.interface";
import { flotte } from "../../domain/ressources/flotte";
import { process } from "@progress/kendo-data-query";
import { WartungenInterface } from "../../../models/wartungen.interface";
import {FlotteService} from "./flotte.service";
import {interval, Observable, Subject, Subscription} from "rxjs";

@Component({
  selector: 'app-flotte',
  templateUrl: './flotte.component.html',
  styleUrls: ['./flotte.component.scss']
})
export class FlotteComponent implements OnInit, OnDestroy{
  public gridView: FlotteInterface[] = [];
  public flotte$: Observable<FlotteResultInterface>
  public flotte: FlotteInterface[]
  public data: FlotteInterface[]
  subscription: Subscription;
  constructor(private flotteService: FlotteService) {
    this.flotteService.initFlotte();
  }

  public ngOnInit(): void {
   this.flotte$ = this.flotteService.getFlotte();
   this.subscription = this.flotte$.subscribe(value => { 
      this.data = value.result;
      this.flotte = this.data;
    });
  }

  public onFilter(inputValue: string): void {
    this.flotte = this.data.filter(value =>
         value.name.toUpperCase().indexOf(inputValue.toUpperCase()) != -1
      || value.netz.toUpperCase().indexOf(inputValue.toUpperCase()) != -1
      || (value.datenlogger.find(value1 =>
          value1.beschreibung.toUpperCase().indexOf(inputValue.toUpperCase()) != -1
          || value1.serien_nummer.toUpperCase().indexOf(inputValue.toUpperCase()) != -1))

    );
  }

  public getField = (args: FlotteInterface) => {
    return `${args.name}_${args.netz}_${args.technischer_platz}`;
  }
  public getFieldLogger = (args: DatenLoggerInterface) => {
    return `${args.serien_nummer}_${args.beschreibung}`;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
