import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {BehaviorSubject, map, Observable, ReplaySubject, Subscription} from "rxjs";
import {FlotteInterface, FlotteResultInterface} from "./flotte.interface";
import { addDays } from "date-fns";

@Injectable({
    providedIn: 'root'
  }
)
export class FlotteService {

  private result: Observable<FlotteResultInterface[]>
  private flotte$ = new ReplaySubject<FlotteResultInterface>(1);
  private flotte: any;
  private flottenSubscription: Subscription;

  constructor(private http: HttpClient) {
    
  }

  initFlotte() {
    if (this.flottenSubscription) {
      this.flottenSubscription.unsubscribe();
    }

    this.flottenSubscription = this.http.get<FlotteResultInterface>(environment.apiUrl + `getFlottenDaten`, {withCredentials: true})
    .pipe(
      map((r) => {
        const currentDate = new Date();
        const intervalCount = 11; // Von 100 bis 110 sind es 11 Intervalle
  
        // Generiere die Intervall-Daten für jede `intervalId` von 100 bis 110
        const intervals = Array.from({ length: intervalCount }, (_, i) => {
          const intervalId = 110 - i;
          const endzeitpunkt = addDays(currentDate, -i * 10);
          const startzeitpunkt = addDays(endzeitpunkt, -10);
          return {
            intervalId,
            startzeitpunkt,
            endzeitpunkt,
          };
        });
  
        // Füge das Intervall-Array `intervals` zu jedem Element in `r.result` hinzu
        r.result = r.result.map((item) => ({
          ...item,
          intervals: intervals,
        }));
  
        return r;
      })
    )
    .subscribe(r => {
      this.flotte = r.result;
      console.log('Meine Flotte: ', this.flotte);
      this.flotte$.next(r);
    });
  }

  getFlotte(): Observable<FlotteResultInterface> {
    return this.flotte$;
  }

  getBahn(name: string) {
    if (!!this.flotte && this.flotte.length > 0) {
      return this.flotte.reduce((acc: any, element: any) => {
        if (element.name === name) {
          return element;
        }
        return acc;
      }, null);
    }
    return null;
  }

  getReferenzBahn() {
    if (!!this.flotte && this.flotte.length > 0) {
      return this.flotte.reduce((acc: any, element: any) => {
        if (element.ist_referenzbahn) {
          return element;
        }
        return acc;
      }, null);
    }
    return null;
  }
}
