<div class="user-menu" *ngIf="isLogin">
    <kendo-menu (select)="onSelect($event)">
      <kendo-menu-item
        class="user-menu-main-element"
        text="Max Mustermann"
        icon="user"
      >
        <ng-template kendoMenuItemTemplate let-item>
          <div class="custom-menu-content">
            <div class="custom-menu-user" *ngIf="getUserData()">{{ getUserData()?.user }}</div>
            <div class="cusom-menu-info">angemeldet</div>
          </div>
        </ng-template>
        <kendo-menu-item
          text="Profil"  
          cssClass="sub-menu-item"
          (select)="openUserProfile()"
        ></kendo-menu-item>

        <kendo-menu-item
          text="Abmelden"  
          cssClass="sub-menu-item"
        ></kendo-menu-item>

      </kendo-menu-item>
    </kendo-menu>
</div>

<kendo-dialog title="Nutzerprofil" *ngIf="userProfileIsOpen" (close)="closeUserProfile()">
    <div class="inner-dialog-container">
        <div class="inner-dialog-title">Folgende Nutzerdaten sind vorhanden: </div>
        <div class="inner-dialog-field" *ngIf="getUserData()?.user">
            <div class="label">Nutzer: </div>
            <div class="value">{{ getUserData()?.user }}</div>
        </div>
        <div class="inner-dialog-field" *ngIf="getUserData()?.mail">
            <div class="label">E-Mail: </div>
            <div class="value">{{ getUserData()?.mail }}</div>
        </div>
        <p *ngIf="!getUserData()">Keine Nutzerdaten gefunden.</p>
    </div>
    <kendo-dialog-actions>
      <button kendoButton (click)="closeUserProfile()">Nutzerprofil schließen</button>
    </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog title="Nutzerabmeldung" *ngIf="logoutDialogIsOpen" (close)="closeLogoutDialog()">
    <div class="inner-dialog-container">
        <div class="inner-dialog-title">Möchten Sie sich wirklich abmelden?</div>
    </div>
    <kendo-dialog-actions>
      <button kendoButton (click)="closeLogoutDialog()">Abbrechen</button>
      <button kendoButton (click)="logoutUser()" themeColor="primary">Abmelden</button>
    </kendo-dialog-actions>
</kendo-dialog>

<div *ngIf="isAnyDialogOpen()" class="dialog-overlay" (click)="closeDialogs()"></div>