import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-filterbox',
  templateUrl: './filterbox.component.html',
  styleUrl: './filterbox.component.scss'
})
export class FilterboxComponent implements OnInit {

  @Output() onFilterBoxChange = new EventEmitter();

  defaultStartDateDayDiff = 250;

  filterBoxForm = new FormGroup({
    startDate: new FormControl(this.getDefaultStartDate() ?? null),
    endDate: new FormControl(new Date())
  });

  lastFilterboxState = {
    startDate: this.filterBoxForm.value.startDate,
    endDate: this.filterBoxForm.value.endDate
  }

  ngOnInit(): void {
    this.onFilterBoxChange.emit(this.filterBoxForm.value);
  }

  getDefaultStartDate(): Date {
    const today = new Date();
    const sevenDaysAgo = new Date(today.setDate(today.getDate() - this.defaultStartDateDayDiff));
    return sevenDaysAgo;
  }

  onSubmitFilterBox(event: any) {
    console.log('TEST!');
    this.onFilterBoxChange.emit(this.filterBoxForm.value);
  }

  filterBoxHasChanges() {
    const filterbox = this.filterBoxForm.value;
    return !(filterbox.startDate === this.lastFilterboxState.startDate &&
             filterbox.endDate === this.lastFilterboxState.endDate);
  }
}
