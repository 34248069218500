<div class="card-container-about">
  <div class="card-header-wrapper">
    <div class="text-container">
      <p class="title_about">Über das Projekt</p>

      <p class="sub-title">Das Projekt in Kürze</p>

      <p class="text">Die Umsetzung erfolgt sowohl unter Auswertung vorhandener Messdaten, aus der Überwachung von Einzelbahnen beider Fahrzeugflotten in Dresden und Leipzig sowie durch die Realisierung einer neuen Messstraßenbahn, die mit umfangreicher Sensorik zur direkten Erfassung des Fahrzeugzustandes ausgestattet wird. Die neuen und in großem Umfang erhobenen Messdaten bilden die Basis für den Einsatz des Maschinellen Lernens, das komplexe Zusammenhänge zwischen Fahrzeugzustand und Signalen der Fahrzeugstandardsensorik und des Fahrzeugbussystems herstellt. Diese Modelle lassen sich letztlich auf jede Einzelbahn anwenden. Es entsteht eine Cloud-basierte Übersicht der Flottenzustände, von der Straßenbahnbetreiber und Wartungsunternehmen zum Beispiel für die effiziente Gestaltung von Wartungszeitpunkten profitieren und die Verfügbarkeit optimieren können.</p>

      <p class="sub-title">Projektpartner</p>

      <p class="text">Robotron als Spezialist für große Datenmengen </p>
      <p class="text">Genau an diesem Punkt bringt Robotron seine Expertise im Bereich der Datenverwaltung und -verarbeitung in das Projekt ein. Unsere Kernaufgabe ist die Erstellung der Cloud-Lösung zum Hosting der Messdaten inkl. Datenbank- und Softwarearchitektur, Datensicherheit und Datenzugang. Daneben sind wir mit der Realisierung eines digitalen Flottenzustandsmanagementsystems, der Datenübernahme aus Übertragung vom Datenlogger sowie dem Datenhosting über die Projektlaufzeit betraut.</p>
      <p class="text">Partner</p>
      <p class="text">In dem interdisziplinären Verbundvorhaben arbeiten wir mit den Instituten für Festkörpermechanik sowie für Leichtbau und Kunststofftechnik der TU Dresden, dem Fraunhofer Institut für Keramische Technologien und Systeme (IKTS), der Leipziger Verkehrsbetriebe GmbH (LVB), deren Wartungsspezialisten IFTEC GmbH & Co. KG, der Dresdner Verkehrsbetriebe AG (DVB) sowie der SDS Schwingungs Diagnose Service GmbH, Estino GmbH und der Leichtbau-Zentrum Sachsen GmbH zusammen.</p>
    </div>
  </div>
</div>
