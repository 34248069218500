import {Component, Injectable, Input, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {DataBindingDirective} from "@progress/kendo-angular-grid";
import {ProblematikInterface} from "../../../models/problematik.interface";
import {problematik} from "../../domain/ressources/ueberblick_problematik";
import {ApixuService} from "../../domain/services/apixu.service";
import {Observable, timer, Subscription} from "rxjs";
import {UserService} from "../../domain/services/user.service";
import {Item} from "../../app.component";
import { WeatherService } from 'src/app/domain/services/weather.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],

})
export class DashboardComponent implements OnInit, OnDestroy {
  // public weatherData: any = {};
  public weatherData!: any;
  subscription: Subscription;
  public items: Array<Item> = [];

  // @Input() weatherData$: Observable<any>;

  constructor(private weatherService: WeatherService,
              private router: Router
  ) {
    // this.weatherData$ = timer(0, 60 * 1000); // aller 60 Sekunden
    this.weatherService.getCurrentWeather().subscribe((weatherData) => {
      this.weatherData = weatherData;
    });
  }

  @ViewChild(DataBindingDirective) dataBinding?: DataBindingDirective;
  public gridData: ProblematikInterface[] = problematik;
  public gridView: any[] = [];
  public streckeData = [
    {km: 5, erschuetterung: 10},
    {km: 6, erschuetterung: 20},
    {km: 10, erschuetterung: 30},
    {km: 12, erschuetterung: 40},
    {km: 8, erschuetterung: 50},
    {km: 20, erschuetterung: 60},
  ]

  public ngOnInit(): void {
    this.gridView = this.gridData;
    //this.sendToAPIXU();
  }

  // sendToAPIXU() {
  //   this.subscription = this.weatherData$.subscribe(x => {
  //     this.apixuService
  //       .getWeather()
  //       .subscribe(data => {
  //         this.weatherData = data;
  //       });
  //   });

  // }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getCurrentWeatherStatus() {
    return this.weatherData ? 'Online' : 'Offline';
  }

  onReferenzBahnPictureClick() {
    this.router.navigate(['referenzbahn']);
  }
}
