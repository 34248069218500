<div class="grid-layout-container" *ngIf="!isLoading">
  <kendo-gridlayout
    [gap]="{ rows: 12, cols: 12 }"
  >

    <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1" [rowSpan]="1">
      <div class="grid-container button-container">
        <div class="button-container-part tram-title">
          {{bahn?.name ?? '-'}}
        </div>
        <div class="button-container-part two-button-container" *ngIf="canSwitchBahn">
          <button kendoButton
                  [svgIcon]="svgArrowLeft"
                  (click)="onSwitchBahn('previous')">
            Voherige Bahn
          </button>
          <button kendoButton
                  [svgIcon]="svgArrowRight"
                  (click)="onSwitchBahn('next')">
            Nächste Bahn
          </button>
        </div>
        <button kendoButton
                class="button-container-part"
                [svgIcon]="svgList"
                (click)="navigateToFlotte()">Zur Flottenansicht</button>
      </div>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item [row]="1" [col]="2" [colSpan]="1" [rowSpan]="1">
      <div class="grid-container tram-container">
        <div class="tram-tile-title">Metadaten:</div>
        <div class="tram-metadata-container">
          <div class="tram-metadata">
            <div class="tram-metadata-label">
              Name:
            </div>
            <div class="tram-metadata-value">
              {{ bahn?.name }}
            </div>
          </div>
          <div class="tram-metadata">
            <div class="tram-metadata-label">
              Netz:
            </div>
            <div class="tram-metadata-value">
              {{ bahn?.netz }}
            </div>
          </div>
          <div class="tram-metadata">
            <div class="tram-metadata-label">
              Technischer Platz:
            </div>
            <div class="tram-metadata-value">
              {{ bahn?.technischer_platz }}
            </div>
          </div>
          <div class="tram-metadata" *ngIf="getBahnDatenLogs()">
            <div class="tram-metadata-label">
              Seriennummer:
            </div>
            <div class="tram-metadata-value">
              {{ getBahnDatenLogs()?.serien_nummer ?? '-' }}
            </div>
          </div>
          <div class="tram-metadata" *ngIf="getLastKilometerstand()">
            <div class="tram-metadata-label">
              Aktueller Kilometerstand:
            </div>
            <div class="tram-metadata-value">
              {{ getLastKilometerstand() | number:'1.0-0' }}
            </div>
          </div>
          <div class="tram-metadata" *ngIf="getLastHauptuntersuchung()">
            <div class="tram-metadata-label">
              Letzte HU:
            </div>
            <div class="tram-metadata-value">
              {{ getLastHauptuntersuchung() | date: 'dd. MMMM y' }}
            </div>
          </div>
          <div class="tram-metadata" *ngIf="getLastWartung()">
            <div class="tram-metadata-label">
              Letzte Wartung:
            </div>
            <div class="tram-metadata-value">
              {{ getLastWartung() | date: 'dd. MMMM y' }}
            </div>
          </div>
        </div>
      </div>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item [row]="1" [col]="3" [colSpan]="1" [rowSpan]="1">
      <div class="grid-container bauteil-container">
        <div class="zustand-tile"
              [ngClass]="{'zustand-tile-select': activeBahnDetails === 'D'}"
              (click)="setActiveBahnDetails('D')">
          <div class="bahn-zustand">
            <div *ngIf="(bahn_zustand?.zustand_tueren == 'i.O.')" class="clearfix bshadow0 pbs">
              <!-- <span class="icon-status" [ngClass]="{
                  'lrv-state_ok': hasIOState(bahn_zustand?.zustand_tueren),
                  'lrv-state_warning': hasWarningState(bahn_zustand?.zustand_tueren),
                  'lrv-state_error': hasErrorState(bahn_zustand?.zustand_tueren)
              }">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
              <span class="path5"></span>
            </span> -->
            </div>
            <div class="bahn-title bahn-title-flex" style="margin-left:10px;">Doppeltüren</div>
          </div>
          <div class="clearfix bshadow0 pbs" 
                style="vertical-align: middle;
                      display: flex;
                      flex-direction: row;
                      justify-content: center;">
            <span class="lrv-doors"></span>
          </div>
        </div>
      </div>

      <div class="grid-container bauteil-container">
        <div class="zustand-tile"
              [ngClass]="{'zustand-tile-select': activeBahnDetails === 'E'}"
              (click)="setActiveBahnDetails('E')">
          <div class="bahn-zustand">
            <div class="clearfix bshadow0 pbs">
              <!-- <span class="icon-status" [ngClass]="{
                  'lrv-state_ok': hasIOState(bahn_zustand?.zustand_sr_antrieb),
                  'lrv-state_warning': hasWarningState(bahn_zustand?.zustand_sr_antrieb),
                  'lrv-state_error': hasErrorState(bahn_zustand?.zustand_sr_antrieb)
              }">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
              <span class="path5"></span>
            </span> -->
            </div>
            <div class="bahn-title bahn-title-flex" style="margin-left:10px;">Elektronik</div>
          </div>
          <div class="clearfix bshadow0 pbs"
                style="vertical-align: middle;
                      display: flex;
                      flex-direction: row;
                      justify-content: center;">
            <span class="lrv-engine"></span>
          </div>
        </div>
      </div>

      <div class="grid-container bauteil-container">
        <div class="zustand-tile"
              [ngClass]="{'zustand-tile-select': activeBahnDetails === 'P'}"
              (click)="setActiveBahnDetails('P')">
          <div class="bahn-zustand">
            <div class="clearfix bshadow0 pbs">
              <!-- <span class="icon-status" [ngClass]="{
                  'lrv-state_ok': hasIOState(bahn_zustand?.zustand_sr_raeder),
                  'lrv-state_warning': hasWarningState(bahn_zustand?.zustand_sr_raeder),
                  'lrv-state_error': hasErrorState(bahn_zustand?.zustand_sr_raeder)
              }">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
              <span class="path5"></span>
            </span> -->
            </div>
            <div class="bahn-title bahn-title-flex" style="margin-left:10px;">Polygonisierung Rad</div>
          </div>
          <div class="clearfix bshadow0 pbs"
                style="vertical-align: middle;
                      display: flex;
                      flex-direction: row;
                      justify-content: center;">
            <span class="lrv-wheels"></span>
          </div>
        </div>
      </div>

      <div class="grid-container bauteil-container">
        <div class="zustand-tile"
              [ngClass]="{'zustand-tile-select': activeBahnDetails === 'V'}"
              (click)="setActiveBahnDetails('V')">
          <div class="bahn-zustand">
            <div class="clearfix bshadow0 pbs">
              <!-- <span class="icon-status" [ngClass]="{
                  'lrv-state_ok': hasIOState(bahn_zustand?.zustand_sr_raeder),
                  'lrv-state_warning': hasWarningState(bahn_zustand?.zustand_sr_raeder),
                  'lrv-state_error': hasErrorState(bahn_zustand?.zustand_sr_raeder)
              }">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
              </span> -->
            </div>
            <div class="bahn-title bahn-title-flex" style="margin-left:10px;">Verortungsdaten</div>
          </div>
          <div class="clearfix bshadow0 pbs"
                style="vertical-align: middle;
                      display: flex;
                      flex-direction: row;
                      justify-content: center;">
            <span class="lrv-location"></span>
          </div>
        </div>
      </div>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="3" [rowSpan]="1">
        <div class="grid-container filter-box-container">
            <div class="filter-box">
              <form class="filter-box-form" [formGroup]="filterBoxForm" (ngSubmit)="onSubmitFilterBox()">

                <div class="filter-box-form-field-container">
                  <div class="filter-box-form-field">

                    <kendo-daterange>

                      <kendo-floatinglabel text="Start-Datum:">
                        <kendo-dateinput
                          kendoDateRangeStartInput
                          formControlName="startDate"
                          [value]="filterBoxForm.value.startDate ?? null"
                        ></kendo-dateinput>

                      </kendo-floatinglabel>
                      <kendo-floatinglabel text="Start-Zeit:">

                        <kendo-timepicker
                          [value]="filterBoxForm.value.startDate ?? null"
                          formControlName="startDate"
                        ></kendo-timepicker>

                      </kendo-floatinglabel>

                      <div class="divider">
                        <div class="line"></div>
                      </div>

                      <kendo-floatinglabel text="End-Datum:">

                        <kendo-dateinput
                          kendoDateRangeEndInput
                          formControlName="endDate"
                          [value]="filterBoxForm.value.endDate ?? null"
                        ></kendo-dateinput>
                      </kendo-floatinglabel>
                      <kendo-floatinglabel text="End-Zeit:">

                        <kendo-timepicker
                          [value]="filterBoxForm.value.endDate ?? null"
                          formControlName="endDate"
                        ></kendo-timepicker>

                      </kendo-floatinglabel>
                    </kendo-daterange>

                  </div>
                </div>
                
                <button [disabled]="!filterBoxHasChanges()" kendoButton type="submit">
                  {{ filterBoxHasChanges() ? 'Auswahl bestätigen' : 'Auswahl bestätigt' }}
                </button>
              </form>
            </div>
        </div>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="3" [rowSpan]="1">
      <div class="grid-container" *ngIf="!loadingDetails">
        <div *ngIf="activeBahnDetails === 'D'">
          <app-doppeltueren [data]="detailsData"></app-doppeltueren>
        </div>
        <div *ngIf="activeBahnDetails === 'E'">
          <app-elektronik
            [data]="detailsData"
          ></app-elektronik>
        </div>
        <div *ngIf="activeBahnDetails === 'P'">
          <app-polygonisierung
            [data]="detailsData"
            [bahnId]="getBahnIdFromUrl()"
            [start]="lastFilterboxState.startDate"
            [end]="lastFilterboxState.endDate"
          ></app-polygonisierung>
        </div>
        <div *ngIf="activeBahnDetails === 'V'">
          <app-verortung
            [tableData]="detailsData"
            [bahnId]="getBahnIdFromUrl()"
          ></app-verortung>
        </div>
      </div>
      <div class="grid-container" *ngIf="loadingDetails">
        <app-loader></app-loader>>
      </div>
    </kendo-gridlayout-item>

  </kendo-gridlayout>
</div>