import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class WeatherService {

  private apiUrl = 'https://api.open-meteo.com/v1/forecast?latitude=51.3397&longitude=12.3731&hourly=temperature_2m,relative_humidity_2m&current_weather=true';

  constructor(private http: HttpClient
  ) {}

  getCurrentWeather(): Observable<any> {
    return this.http.get<any>(this.apiUrl).pipe(
      map(data => {
        // Daten für aktuelle Temperatur und Zeit
        const currentTemp = data.current_weather.temperature;
        const temperatureTime = moment.tz(data.current_weather.time, 'GMT');

        // Werte aus den stündlichen Daten für Luftfeuchtigkeit vor dem aktuellen Temperaturzeitstempel
        let latestHumidity = null;
        let humidityTime = null;

        for (let i = 0; i < data.hourly.time.length; i++) {
          const forecastTime = moment.tz(data.hourly.time[i], 'GMT');
          if (forecastTime.isSameOrBefore(temperatureTime)) {
            latestHumidity = data.hourly.relative_humidity_2m[i];
            humidityTime = forecastTime;
          } else {
            break;
          }
        }

        return {
          temperature: currentTemp,
          temperatureTime: temperatureTime.format(),
          humidity: latestHumidity,
          humidityTime: humidityTime ? humidityTime.format() : null
        };
      })
    );
  }
}
