<div class="outer-poly-container">
    <div class="heatmap-chart-container">
        <div *ngIf="heatmapData.length === 0">
          Keine Heatmap Daten für den ausgewählten Zeitintervall gefunden.. :/
        </div>
        <kendo-chart (seriesClick)="onCellClick($event)" *ngIf="heatmapData.length > 0">
            <kendo-chart-title
                text="Rad / Ordnung"
                [margin]="{ left: 40 }"
            ></kendo-chart-title>
        
            <kendo-chart-series>
              <kendo-chart-series-item
                type="heatmap"
                [color]="getCellColor"
                [data]="heatmapData"
                valueField="'value'"
                xField="x"
                yField="y"
                [markers]="{ type: 'roundedRect', border: { width: 2 } }">
                <kendo-chart-series-item-tooltip>
                    <ng-template let-value="value">
                        {{ value.value }}
                    </ng-template>
                </kendo-chart-series-item-tooltip>
                <kendo-chart-series-item-labels
                    [visible]="false">
                </kendo-chart-series-item-labels>
              </kendo-chart-series-item>
            </kendo-chart-series>
        
            <kendo-chart-x-axis>
                <kendo-chart-x-axis-item
                    [labels]="{ content: xAxisLabelContent, rotation: 35 }"
                >
                </kendo-chart-x-axis-item>
            </kendo-chart-x-axis>
        
            <kendo-chart-y-axis>
                <kendo-chart-y-axis-item
                    [reverse]="true"
                    [line]="{ visible: false }"
                    [labels]="{ content: yAxisLabelContent }"
                >
                </kendo-chart-y-axis-item>
            </kendo-chart-y-axis>
        </kendo-chart>
    </div>
    <div class="plott-chart-container">

      <app-loader *ngIf="firstLineChartIsLoading || secondLineChartIsLoading"></app-loader>

      <kendo-chart [title]="{ text: 'Schädigungs-Inkrement' }" [legend]="{ visible: false }" *ngIf="selectedCell && !firstLineChartIsLoading">
        <kendo-chart-series>
          <kendo-chart-series-item
            type="scatter"
            name="Daten"
            [data]="lineChartTransformed.data"
            xField="x"
            yField="y"
          >
          </kendo-chart-series-item>
        </kendo-chart-series>
      
        <kendo-chart-x-axis>
          <kendo-chart-x-axis-item
            [title]="{ text: lineChartTransformed.xAxisLabel}"
            [labels]="{ format: '{0:dd. MMM HH:mm}', rotation: -25 }"
          >
          </kendo-chart-x-axis-item>
        </kendo-chart-x-axis>
      
        <kendo-chart-y-axis>
          <kendo-chart-y-axis-item
            [title]="{ text: firstLineChartLabel}"
            [labels]="{ format: '{0}' }"
          >
          </kendo-chart-y-axis-item>
        </kendo-chart-y-axis>
      
        <kendo-chart-series-item-tooltip background="#5a80b2">
          <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-category="category" let-dataItem="dataItem">
            <span>
            </span>
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart>

      <div class="multiplicator-input-container" *ngIf="selectedCell && !firstLineChartIsLoading">
        <kendo-label text="Amplitude in Millimeter:">
          <kendo-textbox placeholder="1" min="1" step="1"
            class="multiplicator-input"
            [value]="yExpoWert.toString()"
            (valueChange)="onChangeXAxisMultiplicator($event)"
            (keypress)="allowOnlyNumbers($event)">
          </kendo-textbox>
        </kendo-label>
      </div>

      <kendo-chart [title]="{ text: 'Polygon-Entwicklung' }" [legend]="{ visible: false }" *ngIf="selectedCell && !secondLineChartIsLoading">
        <kendo-chart-series>
          <kendo-chart-series-item
            type="scatter"
            name="Daten"
            [data]="lineChartTransformed.data"
            xField="x"
            yField="yExpoWert"
          >
          </kendo-chart-series-item>
        </kendo-chart-series>
      
        <kendo-chart-x-axis>
          <kendo-chart-x-axis-item
            [title]="{ text: lineChartTransformed.xAxisLabel}"
            [labels]="{ format: '{0:dd. MMM HH:mm}', rotation: -25 }"
            [line]="{ width: 1, color: '#000' }"
          >
          </kendo-chart-x-axis-item>
        </kendo-chart-x-axis>
      
        <kendo-chart-y-axis>
          <!-- <kendo-chart-y-axis-item
            [title]="{ text: secondLineChartLabel }"
          > -->
          <kendo-chart-y-axis-item
            [title]="{ text: 'Wert' }"
            [min]="lineChartTransformed.yAxisMin"
            [max]="lineChartTransformed.yAxisMax"
          >
          </kendo-chart-y-axis-item>
        </kendo-chart-y-axis>
      
        <kendo-chart-series-item-tooltip background="#5a80b2">
          <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-category="category" let-dataItem="dataItem">
            <span>
            </span>
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart>

    </div>
</div>

<div id="focus-container" style="position: fixed; top: 0; left: 0; width: 0; height: 0; overflow: hidden;"></div>