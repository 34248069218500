import {Injectable} from '@angular/core';
import { HttpClient, HttpResponse } from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) {
  }

  getUser(): Observable<HttpResponse<any>> {
    return this.http.get(environment.authUrl + `me`, {observe: 'response', withCredentials: true}
    );
  }

  logoutUser() {
    window.location.href = environment.authUrl + 'logout';
  }
}
