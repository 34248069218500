
<div class="verortung-container">
    <div class="verortung-table-container">
        <kendo-grid
            class="verortung-table"
            [data]="gridView"
            [height]="400"
            [pageable]="true"
            [pageSize]="pageSize"
            (pageChange)="pageChange($event)"
            (cellClick)="onCellClick($event)"
            [rowClass]="rowCallback"
        >
            <kendo-grid-column field="intervall_id" title="Intervall ID"> </kendo-grid-column>
            <!-- <kendo-grid-column field="logger_id" title="Logger ID"> </kendo-grid-column> -->
            <!-- <kendo-grid-column field="intervall_logger_id_tuple" title="Intervall-Logger"> </kendo-grid-column> -->
            <!-- <kendo-grid-column field="logger_name" title="Logger Name"> </kendo-grid-column> -->
            <kendo-grid-column field="anzahl_punkte" title="Anzahl Punkte"> </kendo-grid-column>
            <kendo-grid-column field="avg_geschwindigkeit" title="Avg. Geschwindigkeit"> </kendo-grid-column>
            <kendo-grid-column field="max_distanz_rounded" title="Gesamtdistanz"> </kendo-grid-column>
            <!-- <kendo-grid-column field="max_geschwindigkeit" title="Max. Geschwindigkeit"> </kendo-grid-column> -->

            <!-- Startzeit mit Date Pipe -->
            <kendo-grid-column field="startzeit" title="Startzeit">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.startzeit | date: 'dd. MMM yyyy, HH:mm' }} Uhr
                </ng-template>
            </kendo-grid-column>
            
            <!-- Endzeit mit Date Pipe -->
            <kendo-grid-column field="endzeit" title="Endzeit">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.endzeit | date: 'dd. MMM yyyy, HH:mm' }} Uhr
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
    <div class="verortung-map-container">
        <app-loader class="loader" *ngIf="verortungIsLoading"></app-loader>
        <div class="color-info-container">
            <div class="color-info" *ngFor="let colorInfo of mapMarkerColorInfos">
                <div class="color-info-label">{{ colorInfo.label }}km/h: </div>
                <div class="color-info-bubble" [ngStyle]="{ 'background-color': colorInfo.color }"></div>
            </div>
        </div>
        <div id="map" style="height: 400px;"></div>
    </div>
</div>