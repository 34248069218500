<div class="grid-layout-container">
    <kendo-gridlayout
        [gap]="{ rows: 12, cols: 12 }"
    >
        <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="2" [rowSpan]="1">
            <div class="grid-container filter-box-container">
                <app-filterbox (onFilterBoxChange)="filterBoxChange($event)"></app-filterbox>
            </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="2" [rowSpan]="1">
            <div class="grid-container table-container">
                <app-loader class="loader" *ngIf="schienenNetzIsLoading"></app-loader>
                <kendo-grid
                    *ngIf="!schienenNetzIsLoading"
                    class="schienennetz-table"
                    [data]="schienenNetzData"
                    [height]="400"
                    [rowClass]="rowCallback"
                    (cellClick)="onCellClick($event)"
                >
                    <kendo-grid-column field="abschnittsid" title="abschnittsid"> </kendo-grid-column>
                    <kendo-grid-column field="avg_mean_speed" title="avg_mean_speed"> </kendo-grid-column>
                    <kendo-grid-column field="avg_mean_acclearation_x" title="avg_mean_acclearation_x"> </kendo-grid-column>
                    <kendo-grid-column field="avg_mean_acclearation_y" title="avg_mean_acclearation_y"> </kendo-grid-column>
                    <kendo-grid-column field="avg_std_y" title="Ø_std_y"> </kendo-grid-column>
                    <kendo-grid-column field="avg_rad_aufstands_kraft_links" title="avg_rad_aufstands_kraft_links"> </kendo-grid-column>
                    <kendo-grid-column field="avg_rad_aufstands_kraft_rechts" title="avg_rad_aufstands_kraft_rechts"> </kendo-grid-column>
                    <!-- <kendo-grid-column field="start_lat" title="start_lat"> </kendo-grid-column>
                    <kendo-grid-column field="start_long" title="start_long"> </kendo-grid-column>
                    <kendo-grid-column field="stop_lat" title="stop_lat"> </kendo-grid-column>
                    <kendo-grid-column field="stop_long" title="stop_long"> </kendo-grid-column> -->

                    <kendo-grid-column field="last_mean_zeitstempel" title="last_mean_zeitstempel">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{ dataItem.last_mean_zeitstempel | date: 'dd. MMM yyyy, HH:mm' }} Uhr
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [row]="1" [col]="3" [colSpan]="1" [rowSpan]="2">
            <div class="grid-container map-container">
                <app-loader class="loader" *ngIf="schienenNetzIsLoading"></app-loader>
                <div id="map"></div>
            </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="1" [rowSpan]="2">
            <div class="grid-container" *ngIf="!!selectedItem">
                <div class="schienen-data-props-container">
                    <button kendoButton
                        class="schienen-data-prop"
                        [ngClass]="{'active-data-prop': prop.topic === activeSchienenDataProp.topic}"
                        *ngFor="let prop of schienenDataProps"
                        (click)="setActiveSchienenDataProp(prop)"
                    >
                        {{ prop.topic }}
                    </button>
                </div>
            </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [row]="3" [col]="2" [colSpan]="1" [rowSpan]="1">
            <div class="grid-container" *ngIf="!!selectedItem && !schienenAbschnittIsLoading">
                <app-loader class="loader" *ngIf="schienenAbschnittIsLoading"></app-loader>
                <div *ngIf="!schienenAbschnittIsLoading">
                    <kendo-chart [title]="{ text: activeSchienenDataProp.topic }">
                        <kendo-chart-series>
                            <kendo-chart-series-item
                                *ngFor="let prop of activeSchienenDataProp.data; let i = index"
                                type="scatter"
                                name="{{ prop.label }}"
                                [data]="prop.values"
                                xField="x"
                                yField="y"
                                [color]="getLineColor(i)"
                            >
                            </kendo-chart-series-item>
                            </kendo-chart-series>
                            <kendo-chart-x-axis>
                                <kendo-chart-x-axis-item
                                    [title]="{ text: 'Zeit' }"
                                    [labels]="{ format: '{0:dd. MMM HH:mm}' }"
                                >
                                </kendo-chart-x-axis-item>
                            </kendo-chart-x-axis>
                            <kendo-chart-y-axis>
                                <kendo-chart-y-axis-item
                                    [title]="{ text: 'Wert' }"
                                    [labels]="{ format: '{0}' }"
                                >
                            </kendo-chart-y-axis-item>
                        </kendo-chart-y-axis>
                        <kendo-chart-tooltip format="{0:dd. MMM HH:mm} Uhr, {1}"> </kendo-chart-tooltip>
                    </kendo-chart>
                </div>
            </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [row]="3" [col]="3" [colSpan]="1" [rowSpan]="1">
            <div class="grid-container" *ngIf="!!selectedItem">
                <app-loader class="loader" *ngIf="schienenAbschnittIsLoading"></app-loader>
                <div *ngIf="!schienenAbschnittIsLoading">
                    <kendo-chart [title]="{ text: 'Anzahl der Einträge pro Tag' }" [legend]="{ visible: false }">
                        <kendo-chart-series>
                          <kendo-chart-series-item
                            type="column"
                            name="Einträge"
                            [data]="barChartData"
                            field="count"
                            categoryField="date"
                          >
                          </kendo-chart-series-item>
                        </kendo-chart-series>
                        <kendo-chart-x-axis>
                          <kendo-chart-x-axis-item
                            [title]="{ text: 'Datum' }"
                            [labels]="{ format: '{0:dd. MMM}', rotation: -25 }"
                          >
                          </kendo-chart-x-axis-item>
                        </kendo-chart-x-axis>
                        <kendo-chart-y-axis>
                          <kendo-chart-y-axis-item
                            [title]="{ text: 'Anzahl der Einträge' }"
                          >
                          </kendo-chart-y-axis-item>
                        </kendo-chart-y-axis>
                      </kendo-chart>
                      
                </div>
            </div>
        </kendo-gridlayout-item>

    </kendo-gridlayout>
</div>