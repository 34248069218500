import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {DrawerComponent, DrawerSelectEvent} from "@progress/kendo-angular-layout";
import {UserService} from "./domain/services/user.service";
import { FlotteService } from './components/flotte/flotte.service';

export interface Item {
  text: string;
  path: string;
  selected?: boolean;
  icon: string;
  disabled: boolean;
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'lrv_twin';
  public expanded = true;
  public items: Array<Item> = [];
  public isLogin: boolean = false;
  selected: string;

  constructor(private router: Router,
              private userService: UserService,
              private flotteService: FlotteService) {
    this.flotteService.initFlotte();
  }

  ngOnInit() {
    this.getUserIsLoggedIn();

    this.selectActiveItem();
  }

  selectActiveItem() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.selected = this.extractMainSegmentFromUrl(event.urlAfterRedirects);
      }
    });
  }

  extractMainSegmentFromUrl(url: string): string {
    const cleanUrl = url.replace(/^\/|\/$/g, '');
    const segments = cleanUrl.split('/');
    return segments[0];
  }

  itemIsSelected(item: any) {
    return item === this.selected ? true : false;
  }

  public onSelect(ev: DrawerSelectEvent): void {
    this.selected = ev.item.path;
    this.router.navigate([ev.item.path]);
  }

  public mapItems(routes: Item[]): Item[] {
    return routes.map((item) => {
      return {
        text: item.text,
        icon: item.icon ? item.icon : "",
        path: item.path ? item.path : "",
        disabled: item.disabled ? true : false
      };
    });
  }

  public getUserIsLoggedIn(): void {
    this.userService.getUser().subscribe({
      next: (resp) => {
        this.isLogin = true;
        this.items = this.mapItems(this.router.config as Item[]);
        this.items = this.items.filter(value => value.icon != "");
        const currentItem = this.items.find(item => item.path === this.selected);
        if (currentItem) {
          this.selected = currentItem.path;
        }
      },
      error: (e) => {
        this.isLogin = false;
        this.router.navigate(['login']);
      }
    })
  }

}
