<div class="doppeltueren-container">
    <div class="grid-container">
        <kendo-grid [data]="tableData">
            <kendo-grid-column field="endzeit" title="Zeitraum"></kendo-grid-column>
            <kendo-grid-column field="motorzustand" title="Systemzustand"> </kendo-grid-column>
            <!-- <kendo-grid-column field="riemen_spannung" title="Riemenspannung"> </kendo-grid-column> -->
            <!-- <kendo-grid-column field="spiel" title="Spiel"> </kendo-grid-column> -->
            <!-- <kendo-grid-column field="missbrauch" title="Missbrauch"> </kendo-grid-column> -->
            <kendo-grid-column field="openings" [title]="'Türöffnungen (' + this.data.result.metainfo.summe_openings + ')'"> </kendo-grid-column>
            <kendo-grid-column field="erroropenings" [title]="'Missbrauchsereignisse (' + this.data.result.metainfo.summe_erroropenings + ')'"> </kendo-grid-column>
        </kendo-grid>
    </div>
    <div class="chart-container">
        <div class="chart-choice">
          <button kendoButton 
                  [ngClass]="{'selected': selectedChartType === 'motorzustand'}" 
                  (click)="setChartType('motorzustand')">
            Systemzustand
          </button>
          
          <!-- <button kendoButton 
                  [ngClass]="{'selected': selectedChartType === 'riemen_spannung'}" 
                  (click)="setChartType('riemen_spannung')">
            Riemenspannung
          </button> -->
          
          <button kendoButton 
                  [ngClass]="{'selected': selectedChartType === 'openings'}" 
                  (click)="setChartType('openings')">
            Türöffnungen
          </button>
        </div>
        <div class="chart">
          <kendo-chart [title]="{ text: chartTitle }" [legend]="{ visible: selectedChartType !== 'motorzustand' }">
            <kendo-chart-series>
                <kendo-chart-series-item
                    *ngIf="selectedChartType === 'motorzustand'"
                    type="scatter"
                    name="Systemzustand"
                    [data]="chartDataMotorzustand"
                    xField="endzeit"
                    yField="motorzustand"
                    [markers]="{ visible: true, size: 4 }">
                </kendo-chart-series-item>
                <kendo-chart-series-item
                    *ngIf="selectedChartType === 'riemen_spannung'"
                    type="scatter"
                    name="Riemenspannung"
                    [data]="chartDataRiemenSpannung"
                    xField="endzeit"
                    yField="riemen_spannung"
                    [markers]="{ visible: true, size: 4 }">
                </kendo-chart-series-item>
                <kendo-chart-series-item
                    *ngIf="selectedChartType === 'openings'"
                    type="scatter"
                    name="Türöffnungen"
                    [data]="chartDataOpenings"
                    xField="endzeit"
                    yField="openings"
                    [markers]="{ visible: true, size: 4 }">
                </kendo-chart-series-item>
                <kendo-chart-series-item
                    *ngIf="selectedChartType === 'openings'"
                    type="scatter"
                    name="Missbrauchsereignisse"
                    [data]="chartDataErrorOpenings"
                    xField="endzeit"
                    yField="erroropenings"
                    [markers]="{ visible: true, size: 4 }"
                    color="#33C1FF">
                </kendo-chart-series-item>
            </kendo-chart-series>
        
            <kendo-chart-x-axis>
              <kendo-chart-x-axis-item
                  [title]="{ text: 'Zeit' }"
                  type="date"
                  baseUnit="hours"
                  [labels]="{ format: '{0:dd.MM. HH:mm}', rotation: -25 }"
                  [majorGridLines]="{ visible: true }">
              </kendo-chart-x-axis-item>
            </kendo-chart-x-axis>
        
            <kendo-chart-y-axis>
                <kendo-chart-y-axis-item
                    *ngIf="selectedChartType === 'motorzustand'"
                    [min]="0" [max]="3" [labels]="{ content: formatYAxisLabel }">
                </kendo-chart-y-axis-item>
                <kendo-chart-y-axis-item
                    *ngIf="selectedChartType !== 'motorzustand'"
                    [title]="{ text: yAxisTitle }"
                    [line]="{ visible: true }"
                    [majorGridLines]="{ visible: true }">
                </kendo-chart-y-axis-item>
            </kendo-chart-y-axis>
        
            <kendo-chart-tooltip>
            </kendo-chart-tooltip>
          </kendo-chart>
        </div>
    </div>
</div>
