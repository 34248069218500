<div class="card-container">
  <div class="page-title">Wartungen im Überblick</div>
  <div class="card-header-wrapper">
    <kendo-grid
      [kendoGridBinding]="gridView"
      [pageSize]="10"
      [pageable]="true"
      [sortable]="true"
      id="wartung-grid"
    >
      <ng-template kendoGridToolbarTemplate>
        <kendo-textbox
          [placeholder]="'Suchbegriff ...'"
          type="text"
          (valueChange)="onFilter($event)"
          [clearButton]="true"
          class="searchfield"
        ><ng-template kendoTextBoxPrefixTemplate>
          <kendo-icon name="search"></kendo-icon>
        </ng-template>
        </kendo-textbox>
      </ng-template>
      <kendo-grid-column
        field="modell"
        [title]="'Modell'"
      ></kendo-grid-column>
      <kendo-grid-column
        field="faelligkeit"
        [title]="'Fälligkeit'"
      ><
      </kendo-grid-column>
      <kendo-grid-column
        field="datum"
        [title]="'Datum'"
      ><
      </kendo-grid-column>
      <kendo-grid-column
        field="pruefergebnis"
        [title]="'Prüfergebnis'"
      ><
      </kendo-grid-column>
      <kendo-grid-column
        field="status"
        [title]="'Status'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>

          <span *ngIf="dataItem.status == 'in Betrieb'" class="badge badge-betrieb">{{dataItem.status}} </span>
          <span *ngIf="dataItem.status == 'Defekt'" class="badge badge-defekt">{{dataItem.status}} </span>
          <span *ngIf="dataItem.status == 'in Wartung'" class="badge badge-wartung">{{dataItem.status}} </span>
        </ng-template>
        <
      </kendo-grid-column>
      <kendo-grid-column
        field=""
        [title]="'Prüfbericht'"
        [width]="130"
        [sortable]="false"
      ><
      </kendo-grid-column>
      <ng-template kendoPagerTemplate>
        <kendo-grid-spacer></kendo-grid-spacer>
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons
          [buttonCount]="3"
        ></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-info></kendo-pager-info>
      </ng-template>
    </kendo-grid>
  </div>
</div>
