import { NgModule } from '@angular/core';
import { RouterModule, Routes} from "@angular/router";
import { APP_BASE_HREF } from "@angular/common";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { FlotteComponent } from "./components/flotte/flotte.component";
import { ReferenzbahnComponent } from "./components/referenzbahn/referenzbahn.component";
import { RailstateComponent } from "./components/railstate/railstate.component";
import { WartungenComponent } from "./components/wartungen/wartungen.component";
import { AboutComponent } from "./components/about/about.component";
import { LoginComponent } from "./main/login/login.component";
import {BrowserModule} from "@angular/platform-browser";
import { FlottenbahnComponent } from './components/flottenbahn/flottenbahn.component';
import { FlottenbahnV2Component } from './components/flottenbahn-v2/flottenbahn-v2.component';
import { SchienennetzComponent } from './components/schienennetz/schienennetz.component';


const  routes = [
  { path: '', component: DashboardComponent, text: 'Home', icon: 'lrv-dashboard', data: {page: 'dashboard'}},
  { path: 'referenzbahn', component: FlottenbahnV2Component, text: 'Referenzbahn', icon: 'lrv-tram', data: {page: 'referenzbahn'}},
  { path: 'flotte', component: FlotteComponent, text: 'Flotte', icon: 'lrv-fleet', data: {page: 'flotte'}},
  { path: 'flotte/bahn/:bahn', component: FlottenbahnV2Component, data: {page: 'bahn'}},
  { path: 'schienennetz', component: SchienennetzComponent, text: 'Schienennetz', icon: 'lrv-rails', data: {page: 'schienennetz' }},
  // { path: 'railstate', component: RailstateComponent, text: 'Railstate', icon: 'lrv-rails', data: {page: 'railstate' }},
  { path: 'wartungen', component: WartungenComponent, text: 'Wartungen', icon: 'lrv-maintenance', data: {page: 'wartungen' }}, //disabled: true
  { path: 'about', component: AboutComponent, text: '', icon: '', data: {page: 'about'}},
  { path: 'login', component: LoginComponent, text: '', icon: '', data: {page: 'login'}},
  
 ];

@NgModule({
  imports:
  [ BrowserModule,
    RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
