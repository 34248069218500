<app-loader class="loader" *ngIf="!flotte"></app-loader>
<div class="card-container" *ngIf="flotte">
  <div class="page-title">Flottenübersicht</div>
  <div class="card-header-wrapper">

    <kendo-textbox
      [placeholder]="'Suchbegriff ...'"
      type="text"
      (valueChange)="onFilter($event)"
      [clearButton]="true"
      class="searchfield"
    >
      <ng-template kendoTextBoxPrefixTemplate>
        <kendo-icon name="search"></kendo-icon>
      </ng-template>
    </kendo-textbox>

  </div>
  <div class="card-list">
    <div *ngFor="let bahn of flotte; let index = index">
      <kendo-card [routerLink]="['/flotte/bahn', bahn.name]" style="cursor:pointer" width="100%">
        <kendo-card-header class="card-header">
          <div class="card-title">
            <div><h1 kendoCardTitle class="title-normal">{{ bahn.name }}</h1></div>
            <div *ngIf="bahn.ist_referenzbahn"><span>Referenzbahn</span></div>

          </div>
        </kendo-card-header>
        <hr
          kendoCardSeparator
        />
        <kendo-card-body>
          <div class="card-body">
            <div class="title-small card-body-column">Technischer Platz</div>
            <div class="title-small card-body-column">Netz</div>
            <div class="title-small card-body-column">Datenlogger</div>
          </div>
          <div class="card-body">
            <div class="card-body-column">{{ bahn.technischer_platz }}</div>
            <div class="card-body-column">{{ bahn.netz }}</div>
            <div class="card-body-column-logger">
              <div class="card-logger">

                <span class="title-small-logger card-column-logger">Seriennummer</span>
                <span class="title-small-logger card-column-logger">Beschreibung</span>
              </div>

              <ng-container *ngFor="let logger of bahn.datenlogger">
                <div class="card-logger">

                  <span class="card-column-logger">{{ logger.serien_nummer }}</span>
                  <span class="card-column-logger">{{ logger.beschreibung }}</span>
                </div>
              </ng-container>
            </div>
          </div>
        </kendo-card-body>
      </kendo-card>
    </div>
  </div>
</div>
