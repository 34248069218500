import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-elektronik',
  templateUrl: './elektronik.component.html',
  styleUrl: './elektronik.component.scss'
})
export class ElektronikComponent implements OnInit {

  @Input() data!: any;
  dataCategories!: string[];
  dataValuesStrom!: number[];
  dataValuesTemperatur!: number[];
  bauteile!: any[];

  constructor() {
  }

  ngOnInit(): void {
      console.log('Test Elektronik: ', this.data);
      this.bauteile = this.getDataBauteile();
  }

  getDataBauteile() {
    return  this.data.map((element: any) => {
      return {
        timestamp: element.timestamp,
        bauteil: (1 - element.aggregiert_bauteil)
      }
  });
    // array = array.reduce((accumulator: any, value: any) => accumulator.concat(value), []);
    // console.log('Bauteile Formatiert Array: ', array);
    // console.log('Bauteile Formatiert Labels: ', this.groupDataByLabel(array));
    // return this.groupDataByLabel(array);
  }

  formatYAxisLabel = (e: any): string => {
    if (e.value === 1) {
      return 'Nein';
    } else if (e.value === 2) {
      return 'Ja';
    }
    return ''; // Leere Labels für Werte außerhalb des Bereichs
  };

  groupDataByLabel(data: any[]): { label: string; values: { timestamp: Date; value: number; }[]; }[] {
    const groupedData = data.reduce((acc: { [key: string]: any }, curr) => {
        const label = curr.label;

        if (!acc[label]) {
            acc[label] = { label, values: [] };
        }

        acc[label].values.push({
            timestamp: curr.timestamp,
            value: curr.value,
        });

        return acc;
    }, {});

    // Konvertiere das Objekt zurück in ein Array und sortiere die Values nach timestamp
    return Object.values(groupedData).map((group: any) => ({
        label: group.label,
        values: group.values.sort((a: { timestamp: Date }, b: { timestamp: Date }) => 
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        )
    }));
  }
}
