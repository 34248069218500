import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlottenbahnService {

  constructor(private http: HttpClient) { }

  getAuswertungTuer(bahnId: string, start: Date, end: Date) {
    console.log('ISO Startdatum: ', this.formatDateToISOStringLocal(start));
    console.log('ISO Enddatum: ', this.formatDateToISOStringLocal(end, false));
    // https://backend-dev.lrvtwin.de/api/auswertung_tuer?bahn_name=Standardbahn%203%20Leipzig&startzeit=2024-10-23T10%3A15%3A00%2B02%3A00&endzeit=2024-10-30T10%3A15%3A90%2B01%3A00
    // https://backend-dev.lrvtwin.de/api/auswertung_tuer?bahn_name=Standardbahn%203%20Leipzig&startzeit=2024-10-23T00%3A00%3A00%2B02%3A00&endzeit=2024-10-30T23%3A59%3A59%2B01%3A00

    const url = `auswertung_tuer?bahn_name=${encodeURIComponent(bahnId)}` + 
                `&startzeit=${encodeURIComponent(this.formatDateToISOStringLocal(start))}` + 
                `&endzeit=${encodeURIComponent(this.formatDateToISOStringLocal(end, false))}`;
    // const url = `auswertung_tuer?bahn_name=${encodeURIComponent(bahnId)}&startzeit=${encodeURIComponent(this.formatDateToISOStringLocal(start))}&endzeit=${encodeURIComponent(this.formatDateToISOStringLocal(end, false))}`;
    return this.http.get(environment.apiUrl + url, {withCredentials: true});
  }

  getPolygonisierungHeatmap(bahnId: string, start: Date, end: Date) {
    const url = `auswertung_polygonisierung_heatmap?bahn_name=${encodeURIComponent(bahnId)}` + 
                `&startzeit=${encodeURIComponent(this.formatDateToISOStringLocal(start))}` +
                `&endzeit=${encodeURIComponent(this.formatDateToISOStringLocal(end, false))}`;
    return this.http.get(environment.apiUrl + url, {withCredentials: true});
  }

  getPolygonisierungLinechart(bahnId: string, start: Date, end: Date, rad: string, ordnung: string, amplitude: number = 1) {
    const url = `auswertung_polygonisierung_linechart?bahn_name=${encodeURIComponent(bahnId)}` + 
                `&startzeit=${encodeURIComponent(this.formatDateToISOStringLocal(start))}` + 
                `&endzeit=${encodeURIComponent(this.formatDateToISOStringLocal(end, false))}` + 
                `&Amplitude=${encodeURIComponent(amplitude)}` + 
                `&rad=${encodeURIComponent(rad)}` + 
                `&ordnung=${encodeURIComponent(ordnung)}`;
    // 'https://backend-dev.lrvtwin.de/api/auswertung_polygonisierung_linechart?bahn_name=L_Referenzbahn&startzeit=2024-01-01T00:00:00+00:00&endzeit=2024-12-31T23:59:59+00:00&rad=1&ordnung=1'
    // 'https://backend-dev.lrvtwin.de/api/auswertung_polygonisierung_linechart?bahn_name=Standardbahn%203%20Leipzig&rad=1&ordnung=1&startzeit=2024-01-01T00%3A00%3A00%2B00%3A00&endzeit=2024-12-31T23%3A59%3A59%2B00%3A00'
    return this.http.get(environment.apiUrl + url, {withCredentials: true});
  }

  getBahnLoggerIntervallIds(bahnId: string, start: Date, end: Date) {
    const url = `getIntervallIds?bahn=${encodeURIComponent(bahnId)}` + 
                `&startzeit=${encodeURIComponent(this.formatDateToISOStringLocal(start))}` +
                `&endzeit=${encodeURIComponent(this.formatDateToISOStringLocal(end, false))}`;
    return this.http.get(environment.apiUrl + url, {withCredentials: true}).pipe(
      // Verwende RxJS `map`, um die zurückgegebenen Daten zu transformieren
      map((response: any) => {
        return response.result.map((item: any) => {
          // Füge das neue Feld "isActive" hinzu und setze es standardmäßig auf `false`
          return {
            ...item,
            isActive: true,
            startzeitTimestamp: new Date(item.startzeit).getTime(),
            endzeitTimestamp: new Date(item.endzeit).getTime()
          };
        });
      })
    );
  }

  getVerortungGesamt(bahnId: string, start: Date, end: Date) {
    const url = `verortung_gesamt?bahn_name=${encodeURIComponent(bahnId)}` +
                `&startzeit=${encodeURIComponent(this.formatDateToISOStringLocal(start))}` +
                `&endzeit=${encodeURIComponent(this.formatDateToISOStringLocal(end, false))}`;
    return this.http.get<any>(environment.apiUrl + url, { withCredentials: true }).pipe(
      map((response) => {
        return {
          ...response, // Übernehme alle existierenden Eigenschaften
          result: response.result.map((entry: any) => ({
            ...entry, // Übernehme alle existierenden Eigenschaften des aktuellen Eintrags
            intervall_logger_id_tuple: entry.intervall_id !== null && entry.logger_id !== null ? entry.intervall_id.toString() + '-' + entry.logger_id.toString() : null,
            avg_geschwindigkeit: entry.avg_geschwindigkeit !== null ? this.roundToOneDecimal(entry.avg_geschwindigkeit * 3.6).toString() + ' km/h' : null,
            max_geschwindigkeit: entry.max_geschwindigkeit !== null ? this.roundToOneDecimal(entry.max_geschwindigkeit * 3.6).toString() + ' km/h' : null,
            max_distanz_rounded: entry.max_distanz !== null ? (this.formatToFiveDigits(entry.max_distanz)).toString() + ' Meter' : null
          }))
        };
      })
    );
  }

  getElektronik(bahnId: string, start: Date, end: Date) {
    const url = `auswertung_elektronik?bahn_name=${encodeURIComponent(bahnId)}` +
                `&startzeit=${encodeURIComponent(this.formatDateToISOStringLocal(start))}` +
                `&endzeit=${encodeURIComponent(this.formatDateToISOStringLocal(end, false))}`;
    return this.http.get<any>(environment.apiUrl + url, { withCredentials: true }).pipe(
      map((response) => {
        console.log('Blaaa: ', response);
      return {
        ...response,
        result: response.result.values.map((entry: any) => ({
          ...entry,
          anomalieStromNumber: entry.anomalie_strom ? 2 : 1,
          anomalieTemperaturNumber: entry.anomalie_temperatur ? 2 : 1,
          timestamp: new Date(entry.endzeit)
        }))
      };
    }));
  }

  getSchienenNetzDaten(start: Date, end: Date) {
    const url = `auswertung_schiene?netz=LPZ` +
    `&startzeit=${encodeURIComponent(this.formatDateToISOStringLocal(start))}` +
    `&endzeit=${encodeURIComponent(this.formatDateToISOStringLocal(end, false))}`;
    return this.http.get(environment.apiUrl + url, {withCredentials: true});
  }

  getSchienenAbschnittDaten(abschnittsId: any, start: Date, end: Date) {
    // const url = `auswertung_schiene_einzelabschnitt?abschnittsid=${encodeURIComponent(abschnittsId)}` +
    const url = `auswertung_schiene_einzelabschnitt?abschnittsid=6741` +
    `&startzeit=${encodeURIComponent(this.formatDateToISOStringLocal(start))}` +
    `&endzeit=${encodeURIComponent(this.formatDateToISOStringLocal(end, false))}`;
    return this.http.get(environment.apiUrl + url, {withCredentials: true});
  }

  getVerortungDetails(bahnId: string, intervallId: number, loggerNumber: string): Observable<any> {
    const url = `verortung_einzeln?bahn_name=${encodeURIComponent(bahnId)}` +
                `&intervall_id=${encodeURIComponent(intervallId.toString())}` +
                `&seriennummer=${encodeURIComponent(loggerNumber)}`;
                // `&startzeit=${encodeURIComponent(this.formatDateToISOStringLocal(start))}` +
                // `&endzeit=${encodeURIComponent(this.formatDateToISOStringLocal(end, false))}`;
    return this.http.get(environment.apiUrl + url, {withCredentials: true}).pipe(
      map((data: any) => {
        // Führe die Array-Überprüfung durch
        if (!Array.isArray(data.result)) {
          return [];
        }
  
        const groupedData: { [key: string]: { latitude: number, longitude: number, geschwindigkeit: number, minGeschwindigkeit: number, maxGeschwindigkeit: number, count: number } } = {};
  
        // Gruppiere Daten nach latitude und longitude
        data.result.forEach((entry: any) => {
          const key = `${entry.latitude},${entry.longitude}`;

          entry.geschwindigkeit = entry.geschwindigkeit * 3.6; // Umrechnung von m/s in km/h
          
          if (!groupedData[key]) {
            groupedData[key] = {
              latitude: entry.latitude,
              longitude: entry.longitude,
              geschwindigkeit: entry.geschwindigkeit,
              minGeschwindigkeit: entry.geschwindigkeit,
              maxGeschwindigkeit: entry.geschwindigkeit,
              count: 1
            };
          } else {
            groupedData[key].geschwindigkeit += entry.geschwindigkeit;
            groupedData[key].count += 1;

            if (entry.geschwindigkeit < groupedData[key].minGeschwindigkeit) {
              groupedData[key].minGeschwindigkeit = entry.geschwindigkeit;
            } else if (entry.geschwindigkeit > groupedData[key].maxGeschwindigkeit) {
              groupedData[key].maxGeschwindigkeit = entry.geschwindigkeit;
            }
          }
        });
  
        // Berechne Durchschnittsgeschwindigkeit und erstelle neues Array
        const result = Object.values(groupedData).map((entry: any) => ({
          latitude: entry.latitude,
          longitude: entry.longitude,
          durchschnittsgeschwindigkeit: entry.geschwindigkeit / entry.count,
          minGeschwindigkeit: entry.minGeschwindigkeit,
          maxGeschwindigkeit: entry.maxGeschwindigkeit,
          anzahl: entry.count
        }));
  
        return result;
      })
    );
  }

  getISOString(date: Date) {
    return date.toISOString(); // .replace('.000Z', '+00:00').replace('.999Z', '+00:00');
  }

  formatDateToISOStringLocal(date: Date, isStart: boolean = true): string {
    // Hole die lokalen Jahr, Monat, Tag, Stunde, Minute und Sekunde
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Monate starten bei 0
    const day = ('0' + date.getDate()).slice(-2);

    let hours;
    let minutes;
    let seconds;

    if (isStart === true) {
      hours = '00';
      minutes = '00';
      seconds = '00';
    } else {
      hours = ('23');
      minutes = ('59');
      seconds = ('59');
    }
  
    // Hole den Zeitzonen-Offset in Minuten
    const offset = -date.getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(offset) / 60);
    const offsetMinutes = Math.abs(offset) % 60;
    const offsetSign = offset >= 0 ? '+' : '-';
    const offsetString = offsetSign + ('0' + offsetHours).slice(-2) + ':' + ('0' + offsetMinutes).slice(-2);
  
    // Baue den ISO-String mit der lokalen Zeit und dem Offset
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${offsetString}`;
  }

  // setDateToStartOfDay(date: Date): Date {
  //   let newDate = new Date(date);
  //   newDate.setHours(0, 0, 0, 0);  // Setzt Stunden, Minuten, Sekunden und Millisekunden auf 0
  //   return newDate;
  // }

  // setDateToEndOfDay(date: Date): Date {
  //   let newDate = new Date(date);
  //   newDate.setHours(23, 59, 59, 999);  // Setzt Stunden auf 23, Minuten auf 59, Sekunden auf 59, Millisekunden auf 999
  //   return newDate;
  // }

  formatToFiveDigits(num: number): number {
    // Konvertiere die Zahl in einen String, um Stellen zu zählen
    const numStr = num.toString();
    const [integerPart, decimalPart] = numStr.split('.');
  
    // Fall 1: Wenn die Zahl vor dem Komma bereits >= 5 Stellen hat
    if (integerPart.length >= 5) {
      return Math.round(num); // Runden auf eine ganze Zahl
    }
  
    // Fall 2: Die Gesamtanzahl an Stellen darf 5 nicht überschreiten
    const remainingDigits = 5 - integerPart.length; // Verfügbare Nachkommastellen
    const factor = Math.pow(10, remainingDigits); // Runden auf die erlaubte Anzahl
    return Math.round(num * factor) / factor;
  }

  roundToOneDecimal(num: number): number {
    return Math.round(num * 10) / 10;
  }
}
