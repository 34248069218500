import { Component, OnInit, Input } from '@angular/core';

import * as L from 'leaflet';
import { Map } from 'leaflet';
// import 'leaflet.markercluster';
// import 'leaflet.heat';

// import 'leaflet.fullscreen/Control.FullScreen.js';
import * as shapefile from 'shapefile';
// import 'leaflet-fullscreen';
import { GeoJsonObject, FeatureCollection } from 'geojson';
import { FlottenbahnService } from '../flottenbahn.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';

// declare module 'leaflet' {
//   namespace control {
//     function fullscreen(v: any): Control;  
//   }
// }

// declare module 'leaflet' {
//   export function heatLayer(latlngs: [number, number, number][], options?: HeatMapOptions): Layer;

//   interface HeatMapOptions {
//     minOpacity?: number;
//     maxZoom?: number;
//     max?: number;
//     radius?: number;
//     blur?: number;
//     gradient?: { [key: number]: string };
//   }
// }

export class VerortungGesamt {
  public intervall_id: number;
  public logger_id: number;
  public logger_name: string;
  public anzahl_punkte: number;
  public avg_geschwindigkeit: number;
  public max_distanz: number;
  public max_geschwindigkeit: number;
  public startzeit: Date;
  public endzeit: Date;
}

@Component({
  selector: 'app-verortung',
  templateUrl: './verortung.component.html',
  styleUrl: './verortung.component.scss'
})
export class VerortungComponent implements OnInit {

  @Input() public bahnId!: any;
  // @Input() public start!: any;
  // @Input() public end!: any;

  @Input() public tableData!: VerortungGesamt[];

  public map: Map;
  lastClickedMarker: L.Marker | null = null;
  public verortungIsLoading: boolean = false;
  // public fullscreenOptions: FullscreenOptions;

  public gridView: GridDataResult;
  public skip = 0;
  public pageSize: number = 20;
  public selectedItem: VerortungGesamt;

  mapMarkerColorInfos = [
    {
      label: '0',
      color: '#8B0000'
    }, {
      label: '10',
      color: '#FF6347'
    }, {
      label: '20',
      color: '#FFA500'
    }, {
      label: '30',
      color: '#FFFF00'
    }, {
      label: '40',
      color: '#ADFF2F'
    }, {
      label: 'ab 50',
      color: '#006400'
    }
  ]

 
  constructor(private flottenbahnService: FlottenbahnService) {
 
  }

  ngOnInit(): void {
    this.verortungIsLoading = true;
    this.loadShapefile();
    
    this.loadItems();
    // this.getAllIntervallAndLoggerData(this.bahnId, this.start, this.end);

    this.initMap();
    // this.addMarkers();
  }

  onMapReady(map: Map) {
    this.map = map;
  }

  private initMap(): void {
    console.log('HIER SOLLTE ES GEHEN!');
    this.verortungIsLoading = false;

    if (this.map) {
      this.map.remove(); // Zerstöre die bestehende Karte
    }

    this.map = L.map('map', {
      // fullscreenControl: true,
      center: [51.3397, 12.3731], // Beispielkoordinaten (z.B. Leipzig)
      zoom: 11
    });

    // this.map.addControl(new L.Control.Fullscreen());

    // Füge den Fullscreen-Button hinzu
    // L.control.fullscreen().addTo(this.map);

    // Optional: Hinzufügen einer Grundkarte (z.B. OpenStreetMap)
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      attribution: '© OpenStreetMap'
    }).addTo(this.map);

  }

  // private getAllIntervallAndLoggerData(bahn: string, start: Date, end: Date): void {
  //   console.log('🦈');
  //   const sub = this.flottenbahnService.getVerortungGesamt(bahn, start, end).subscribe((verortungGesamt: any) => {

  //     console.log('🦈 Verortung Gesamt: ', verortungGesamt.result);
  //     this.tableData = verortungGesamt.result;
  //     this.loadItems();

  //     sub.unsubscribe();
  //   });
  // }

  private loadShapefile(): void {
    // Definiere den Pfad zum Shapefile
    const shpPath = '/assets/maps/leipzig/shapefile.shp';
    const dbfPath = '/assets/maps/leipzig/shapefile.dbf';

    // Erstelle ein GeoJSON-Objekt mit dem richtigen Typ
    const geoJsonData: FeatureCollection = {
        type: 'FeatureCollection',
        features: []
    };

    // Lade und konvertiere das Shapefile in GeoJSON
    shapefile.open(shpPath, dbfPath)
      .then((source) => {
        // Iteriere über die Shapefile-Daten und konvertiere sie in GeoJSON-Format
        const log = (result: any): void => {
          if (result.done) {
            // Füge das GeoJSON zur Leaflet-Karte hinzu
            L.geoJSON(geoJsonData as GeoJsonObject).addTo(this.map);
          } else {
            geoJsonData.features.push(result.value);
            source.read().then(log); // Hier kein return mehr
          }
        };

        source.read().then(log);
      })
      .catch((error) => console.error(error));
  }

  addMarkers() {
    this.verortungIsLoading = true;
    this.flottenbahnService.getVerortungDetails(this.bahnId, this.selectedItem.intervall_id, this.selectedItem.logger_name).subscribe((data: any) => {
      console.log('Verortung Details Daten: ', data);
      this.initMap();

      data.forEach((point: any) => {
        const latLng: L.LatLngExpression = [point.latitude, point.longitude];
        const color = this.getMarkerColor(point.durchschnittsgeschwindigkeit);

        const customMarker = L.marker(latLng, {
          icon: L.divIcon({
            className: 'custom-marker',
            html: `<div style="background-color: ${color}; width: 12px; height: 12px; border-radius: 50%;"></div>`,
          }),
        });

        // Event-Listener für Klick-Events auf den Marker hinzufügen
        customMarker.on('click', () => {
          // Zurücksetzen des letzten angeklickten Markers, falls vorhanden
          if (this.lastClickedMarker) {
            this.lastClickedMarker.setIcon(
              L.divIcon({
                className: 'custom-marker',
                html: `<div style="background-color: ${color}; width: 12px; height: 12px; border-radius: 50%;"></div>`,
              })
            );
            this.lastClickedMarker.setZIndexOffset(0); // Zurücksetzen des z-index
          }

          // Marker hervorheben
          customMarker.setIcon(
            L.divIcon({
              className: 'custom-marker',
              html: `<div style="background-color: #5a80b2; width: 20px; height: 20px; margin-left: -4px; margin-top: -4px; border-radius: 50%; box-shadow: #00000078 0px 0px 7px 3px;"></div>`,
            })
          );
          customMarker.setZIndexOffset(9999); // Setze z-index höher für den ausgewählten Marker

          // Popup öffnen mit einer kleinen Verzögerung, um sicherzustellen, dass das Icon-Update abgeschlossen ist
          setTimeout(() => {
            customMarker.bindPopup(`
              <strong>Anzahl der Datensätze:</strong> ${point.anzahl}
              <br><strong>Durchschnittsgeschwindigkeit:</strong> ${point.durchschnittsgeschwindigkeit.toFixed(2)} km/h
              <br><strong>Min. Geschwindigkeit:</strong> ${point.minGeschwindigkeit.toFixed(2)} km/h
              <br><strong>Max. Geschwindigkeit:</strong> ${point.maxGeschwindigkeit.toFixed(2)} km/h
              <br><strong>Latitude:</strong> ${point.latitude}
              <br><strong>Longitude:</strong> ${point.longitude}
            `).openPopup();
          }, 50); // Verzögerung von 50 Millisekunden

          // Setze den zuletzt geklickten Marker
          this.lastClickedMarker = customMarker;
        });

        // Initiales Hinzufügen des Markers zur Karte und Popup-Bindung
        customMarker.addTo(this.map).bindPopup(`
          <strong>Anzahl der Datensätze:</strong> ${point.anzahl}
          <br><strong>Durchschnittsgeschwindigkeit:</strong> ${point.durchschnittsgeschwindigkeit.toFixed(2)} km/h
          <br><strong>Min. Geschwindigkeit:</strong> ${point.minGeschwindigkeit.toFixed(2)} km/h
          <br><strong>Max. Geschwindigkeit:</strong> ${point.maxGeschwindigkeit.toFixed(2)} km/h
          <br><strong>Latitude:</strong> ${point.latitude}
          <br><strong>Longitude:</strong> ${point.longitude}
        `);
      });
    }, (error) => {

      console.log('Add Markers Error: ', error);
      this.verortungIsLoading = false;
    });
  }

  getMarkerColor(geschwindigkeit: number): string {
    if (geschwindigkeit <= 0) {
      return '#8B0000'; // Dunkelrot für Geschwindigkeit 0
    } else if (geschwindigkeit <= 10) {
      const ratio = geschwindigkeit / 10;
      return this.interpolateColor('#8B0000', '#FF6347', ratio); // Übergang von Dunkelrot zu Hellrot
    } else if (geschwindigkeit <= 20) {
      const ratio = (geschwindigkeit - 10) / 10;
      return this.interpolateColor('#FF6347', '#FFA500', ratio); // Übergang von Hellrot zu Orange
    } else if (geschwindigkeit <= 30) {
      const ratio = (geschwindigkeit - 20) / 10;
      return this.interpolateColor('#FFA500', '#FFFF00', ratio); // Übergang von Orange zu Gelb
    } else if (geschwindigkeit <= 40) {
      const ratio = (geschwindigkeit - 30) / 10;
      return this.interpolateColor('#FFFF00', '#ADFF2F', ratio); // Übergang von Gelb zu Hellgrün
    } else if (geschwindigkeit <= 50) {
      const ratio = (geschwindigkeit - 40) / 10;
      return this.interpolateColor('#ADFF2F', '#006400', ratio); // Übergang von Hellgrün zu Dunkelgrün
    } else {
      return '#006400'; // Dunkelgrün für Geschwindigkeit >= 50
    }
  }
  

  interpolateColor(color1: string, color2: string, ratio: number): string {
    const hex = (color: string) => {
      return color.replace('#', '').match(/.{1,2}/g)?.map((x) => parseInt(x, 16)) || [];
    };
  
    const [r1, g1, b1] = hex(color1);
    const [r2, g2, b2] = hex(color2);
  
    const r = Math.round(r1 + ratio * (r2 - r1));
    const g = Math.round(g1 + ratio * (g2 - g1));
    const b = Math.round(b1 + ratio * (b2 - b1));
  
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();

    // Optionally, clear the selection when paging
    // this.mySelection = [];
  }

  private loadItems(): void {
    this.gridView = {
      data: this.tableData.slice(this.skip, this.skip + this.pageSize),
      total: this.tableData.length,
    };
  }

  onCellClick(event: any): void {
    console.log('Cell clicked:', event.dataItem);
    console.log('Column field:', event.column.field); // Gibt das Feld der Spalte zurück
    console.log('Cell value:', event.dataItem[event.column.field]); // Gibt den Wert der angeklickten Zelle zurück
  
    this.selectedItem = event.dataItem;
    this.addMarkers();
  }
  
  rowCallback = (context: any) => {
    // Überprüfe, ob die aktuelle Zeile die ausgewählte ist
    return this.selectedItem === context.dataItem ? 'highlighted-row' : '';
  };
}
