import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { FlotteInterface } from "../flotte/flotte.interface";
import { flotte } from "../../domain/ressources/flotte";
import { bahnzustand } from "../../domain/ressources/bahn_zustand";
import { BahnInterface } from "../../../models/bahn.interface";
import { BahnDetailsInterface } from "../../../models/bahnDetails.interface";
import { bahnDetailzustand } from "../../domain/ressources/bahn_details";
import { FlotteService } from '../flotte/flotte.service';
import { FormControl, FormGroup } from '@angular/forms';
import { cartIcon, SVGIcon, listUnorderedIcon, arrowLeftIcon, arrowRightIcon } from '@progress/kendo-svg-icons';
import { FlottenbahnService } from './flottenbahn.service';
import { LoggerIntervallId } from 'src/models/intervallId.interface';
import { DatePipe } from '@angular/common';

const sliderValidator = (validRange: number[]) => (control: any) => {
  return control.value[0] >= validRange[0] && control.value[1] <= validRange[1]
    ? null
    : {
        valid: false,
      };
};

@Component({
  selector: 'app-flottenbahn-v2',
  templateUrl: './flottenbahn-v2.component.html',
  styleUrls: ['./flottenbahn-v2.component.scss']
})
export class FlottenbahnV2Component {

  bahnDetails = {
    doppeltueren: 'D',
    elektronik: 'E',
    polygonisierungRad: 'P',
    verortungsdaten: 'V'
  }
  activeBahnDetails = this.bahnDetails.doppeltueren; /* 🔥🔥🔥 */
  detailsData!: any;

  public value: [number, number] = [3, 5];
  defaultStartDateDayDiff = 300;
  public min!: number;
  public max!: number;
  public smallStep = 1;
  public largeStep = 10;
  public step = 1;
  public intervalIdSliderTitle = '-';

  public svgCart: SVGIcon = cartIcon;
  public svgList: SVGIcon = listUnorderedIcon;
  public svgArrowLeft: SVGIcon = arrowLeftIcon;
  public svgArrowRight: SVGIcon = arrowRightIcon;

  

  bahn: FlotteInterface |undefined;
  bahn_zustand: BahnInterface | undefined;
  bahn_details: BahnDetailsInterface[];
  bahn_detail: BahnDetailsInterface[];

  public referenzBahnName = 'L_Referenzbahn';
  public restlaufdauerData = [3, 5, 10, 18, 25];
  public verschleisszustandData = [3, 5, 10, 18];
  public ausfallrateData = [3, 5, 10, 18];
  public categories = [50, 100, 150,200,250];
  public grenzeData = [10,10,10,10,10];

  public selectZustand = 1;

  public isReferenzBahn: boolean = false;
  getFlotteIfMissed: boolean = true;
  isLoading: boolean = true;
  public canSwitchBahn: boolean = true;
  loadingDetails: boolean = false;

  public loggerIntervallIds: LoggerIntervallId[];
  public filterBoxIntervallIsLoading: boolean = false;

  filterBoxForm = new FormGroup({
    startDate: new FormControl(this.getDefaultStartDate() ?? null),
    endDate: new FormControl(new Date())
  });

  lastFilterboxState = {
    startDate: this.filterBoxForm.value.startDate,
    endDate: this.filterBoxForm.value.endDate
  }

  constructor( private route: ActivatedRoute,
               private flotteService: FlotteService,
               private router: Router,
               private flottenbahnService: FlottenbahnService, 
               private datePipe: DatePipe) {}
 
  ngOnInit(): void {
    this.initFlottenbahn();

    // this.getAuswertungTuer();
    // this.getPolygonisierungHeatmap();
    // this.getPolygonisierungLinechart();
    this.getDetails();
  }

  // getBahnLoggerIntervallIds() {
  //   this.filterBoxIntervallIsLoading = true;
  //   const bahnName = this.bahn?.name;
  //   const start = this.getStartDate();
  //   const end = this.getEndDate();

  //   console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx');
  //   console.log('bahnName: ', bahnName);
  //   console.log('start: ', start);
  //   console.log('end: ', end);
    // if (!!bahnName && !!start && !!end) {
      // let sub = this.flottenbahnService.getBahnLoggerIntervallIds(bahnName, start, end).subscribe((data: any) => {
      //   console.log('Get bahn logger intervall ids: ', data);
      //   this.loggerIntervallIds = data;
        // this.setLoggerIntervalIdsTitle();
      //   sub.unsubscribe();
      // });
    // }
  // }

  filterBoxHasChanges() {
    const filterbox = this.filterBoxForm.value;
    return !(filterbox.startDate === this.lastFilterboxState.startDate &&
             filterbox.endDate === this.lastFilterboxState.endDate);
  }

  setLastFilterBox() {
    const filterbox = this.filterBoxForm.value;
    this.lastFilterboxState.startDate = filterbox.startDate;
    this.lastFilterboxState.endDate = filterbox.endDate;
  }

  getStartDate() {
    return this.filterBoxForm.value.startDate ?? new Date();
  }

  getEndDate() {
    return this.filterBoxForm.value.endDate ?? new Date();
  }

  getDetails() {
    this.loadingDetails = true;

    const start = this.getStartDate() ?? new Date();
    const end = this.getEndDate() ?? new Date();

    switch (this.activeBahnDetails) {
      case this.bahnDetails.doppeltueren:
        this.getAuswertungTuer(start, end);
        break;
      
      case this.bahnDetails.polygonisierungRad:
        this.getPolygonisierungHeatmap(start, end);
        break;

      case this.bahnDetails.verortungsdaten:
        this.getVerortungTableData(start, end);
        break;

      case this.bahnDetails.elektronik:
        this.getElektronik(start, end);
        break;
      
      default:
        // ...
    }
  }

  //'2024-01-01T00:00:00+00:00' / '2024-12-31T23:59:59+00:00'
  getAuswertungTuer(start: Date, end: Date) {
    let bahnId = this.route.snapshot.paramMap.get('bahn') ?? '';
    bahnId.length === 0 ? bahnId = this.referenzBahnName : null;

    this.flottenbahnService.getAuswertungTuer(bahnId, start, end).subscribe((data: any) => {
      console.log('Auswertung Tür: ', data);
      this.detailsData = data;
      this.setLastFilterBox();
      this.loadingDetails = false;
    });
  }

  getPolygonisierungHeatmap(start: Date, end: Date) {
    let bahnId = this.route.snapshot.paramMap.get('bahn') ?? '';
    bahnId.length === 0 ? bahnId = this.referenzBahnName : null;

    this.flottenbahnService.getPolygonisierungHeatmap(bahnId, start, end).subscribe((data: any) => {
      console.log('Polygonisierung Heatmap: ', data);
      this.detailsData = data;
      this.setLastFilterBox();
      this.loadingDetails = false;
    });
  }

  getVerortungTableData(start: Date, end: Date) {
    let bahnId = this.route.snapshot.paramMap.get('bahn') ?? '';
    bahnId.length === 0 ? bahnId = this.referenzBahnName : null;

    const sub = this.flottenbahnService.getVerortungGesamt(bahnId, start, end).subscribe((data: any) => {

      console.log('🦈 Verortung Gesamt (NEU): ', data.result);
      this.detailsData = data.result;

      this.loadingDetails = false;
      sub.unsubscribe();
    });
  }

  getElektronik(start: Date, end: Date) {
    let bahnId = this.route.snapshot.paramMap.get('bahn') ?? '';
    bahnId.length === 0 ? bahnId = this.referenzBahnName : null;

    const sub = this.flottenbahnService.getElektronik(bahnId, start, end).subscribe((data: any) => {

      console.log('🦈 Elektronik Gesamt (NEU): ', data);
      this.detailsData = data.result;

      this.loadingDetails = false;
      sub.unsubscribe();
    });
  }

  getBahnIdFromUrl() {
    let bahnId = this.route.snapshot.paramMap.get('bahn') ?? '';
    bahnId.length === 0 ? bahnId = this.referenzBahnName : null;
    return bahnId;
  }

  initFlottenbahn() {
    this.getBahn();
  }

  getFlotte() {
    return this.flotteService.getFlotte();
  }

  onSwitchBahn(direction: 'next' | 'previous') {
    let subscription = this.getFlotte().subscribe((flotte) => {
      console.log('Flotte: ', flotte);
      console.log('Bahn: ', this.bahn);



      const target = this.getNextOrPreviousObject(flotte.result, this.bahn, direction);
      
      if (direction === 'next') {
        console.log('Nächste Bahn: ', target);
      }

      if (direction === 'previous') {
        console.log('Voherige Bahn: ', target);
      }

      this.router.navigate(['flotte/bahn/' + target.name]).then(() => {
        this.initFlottenbahn();
        this.getDetails();
        subscription.unsubscribe();
      });
    });
  }
  
  getNextOrPreviousObject(array: any[], currentObject: any, direction: 'next' | 'previous'): any {
    // Finde den Index des aktuellen Objekts im Array anhand der Property 'name'
    const currentIndex = array.findIndex(obj => obj.name === currentObject.name);
  
    if (currentIndex === -1) {
      throw new Error('Objekt nicht im Array gefunden');
    }
  
    // Logik für den "next" Button
    if (direction === 'next') {
      // Prüfen, ob das aktuelle Objekt das letzte ist, wenn ja, das erste zurückgeben
      const nextIndex = (currentIndex + 1) % array.length;
      return array[nextIndex];
    }
  
    // Logik für den "previous" Button
    if (direction === 'previous') {
      // Prüfen, ob das aktuelle Objekt das erste ist, wenn ja, das letzte zurückgeben
      const previousIndex = (currentIndex - 1 + array.length) % array.length;
      return array[previousIndex];
    }
  
    // Falls die Richtung nicht korrekt ist, gib einen Fehler zurück
    throw new Error('Ungültige Richtung angegeben');
  }

  setActiveBahnDetails(detail: string) {
    this.activeBahnDetails = detail;
    this.getDetails();
  }

  navigateToFlotte() {
    this.router.navigate(['flotte']);
  }

  getDefaultStartDate(): Date {
    const today = new Date();
    const sevenDaysAgo = new Date(today.setDate(today.getDate() - this.defaultStartDateDayDiff));
    return sevenDaysAgo;
  }

  onSubmitFilterBox() {
    console.log('🍏 SUBMIT!');
    this.getDetails();
  }

  getBahn() {
    const bahnName = this.route.snapshot.paramMap.get('bahn');

    if (bahnName != null && bahnName != this.referenzBahnName) { // Flotte -> Flottenbahn
      this.canSwitchBahn = true;

      // Flottenbahn
      this.bahn = this.flotteService.getBahn(bahnName);
      this.bahn_zustand = bahnzustand[1];

      if (!this.bahn && this.getFlotteIfMissed) {
        this.getFlotteIfMissed = false;
        this.flotteService.getFlotte().subscribe(() => {
          this.getBahn();
        });
      } else {
        this.isLoading = false;
      }
    } else {

      if (bahnName === null) { // Referenzbahn
        this.canSwitchBahn = false;
      } else { // Flotte -> Referenzbahn
        this.canSwitchBahn = true;
      }

      // Referenzbahn
      this.isReferenzBahn = true;
      this.flotteService.getFlotte().subscribe(() => {
        this.bahn = this.flotteService.getReferenzBahn();
      });
      this.bahn_zustand = bahnzustand[1];
      this.isLoading = false;
    }

    console.log('Meine Bahn: ', this.bahn);

    // this.setIntervalIds();
    // this.getBahnLoggerIntervallIds();
  }

  getActiveLoggerIntervallIds() {
    if (!!this.loggerIntervallIds && this.loggerIntervallIds.length > 0) {
      console.log('Test Daten: ', this.loggerIntervallIds);
      return this.loggerIntervallIds.filter((logInt: LoggerIntervallId) => logInt.isActive === true);
    }
    return [];
  }

  // setLoggerIntervalIdsTitle() {
  //   const logIntArray = this.getActiveLoggerIntervallIds();

  //   console.log('Filter Box Form: ', this.filterBoxForm);
  //   console.log('Log Int Array: ', logIntArray);
  //   // Prüfe, ob das Formular und das Intervall-Array existieren
  //   if (this.filterBoxForm && logIntArray && logIntArray.length > 0) {
  //     // Setze den Mindest- und Höchstwert des Intervall-Arrays als Wert für intervalIds im Formular
  //     // this.min = Math.min(...logIntArray.map((logInt: LoggerIntervallId) => logInt.startzeitTimestamp));
  //     // this.max = Math.max(...logIntArray.map((logInt: LoggerIntervallId) => logInt.endzeitTimestamp));

  //     const startLoggerIntervall = this.getStartDateLoggerIntervall(this.min);
  //     const endLoggerIntervall = this.getEndDateLoggerIntervall(this.max);

  //     console.log('Datum vorher: ', logIntArray.map((logInt: LoggerIntervallId) => new Date(logInt.startzeit).getTime()));
  //     console.log('Start Date Intervall: ', startLoggerIntervall);
  //     console.log('End Date Intervall: ', endLoggerIntervall);

  //     this.intervalIdSliderTitle = 'Zeitintervall: ' + this.datePipe.transform(startLoggerIntervall?.startzeit, 'dd. MMMM HH:mm') + ' Uhr' +
  //                                  ' - ' + this.datePipe.transform(endLoggerIntervall?.endzeit, 'dd. MMMM HH:mm') + ' Uhr' +
  //                                  ' (Logger: ' + startLoggerIntervall?.logger_seriennummer + ', Intervall: ' + startLoggerIntervall?.intervallid + ' - ' +
  //                                  'Logger: ' + endLoggerIntervall?.logger_seriennummer + ', Intervall: ' + endLoggerIntervall?.intervallid + ')';
  //   }
  //   this.filterBoxIntervallIsLoading = false;
  // }

  // getStartDateLoggerIntervall(startDateTimestamp: number) {
  //   return this.getActiveLoggerIntervallIds().find((logInt: LoggerIntervallId) => logInt.startzeitTimestamp === startDateTimestamp);
  // }

  // getEndDateLoggerIntervall(endDateTimestamp: number) {
  //   return this.getActiveLoggerIntervallIds().find((logInt: LoggerIntervallId) => logInt.endzeitTimestamp === endDateTimestamp);
  // }

  // getLoggerIntervalWithLogInt(log: string, int: number) {
  //   return this.loggerIntervallIds.find((logInt: LoggerIntervallId) => logInt.logger_seriennummer === log && logInt.intervallid === int);
  // }

  getBahnDatenLogs() {
    if (!!this.bahn && this.bahn.datenlogger && this.bahn.datenlogger.length > 0) {
      return this.bahn.datenlogger[0];
    }
    return null;
  }

  getBackgroundClass( bahn_zustand: string | undefined ) {
    if (bahn_zustand == "E") {
      return 'red-light-bg';
    } else if (bahn_zustand == "W") {
      return 'yellow-light-bg';
    } else  {
      return 'green-light-bg';
    }
  }

  getLastKilometerstand() : number | undefined {
    if (this.bahn?.kilometerstand && this.bahn?.kilometerstand.length > 0) {
      return this.bahn.kilometerstand[0].kilometer_stand_berechnet;
    }
    return undefined;
  }

  getLastWartung() : Date | undefined {
    if (this.bahn?.wartung && this.bahn?.wartung.length > 0) {
      return this.bahn.wartung[0].zeitstempel;
    }
    return undefined;
  }

  getLastHauptuntersuchung() : Date | undefined {
    if (this.bahn?.hauptuntersuchung && this.bahn?.hauptuntersuchung.length > 0) {
      return this.bahn.hauptuntersuchung[0].zeitstempel;
    }
    return undefined;
  }

  hasIOState(state: string | undefined) {
    return !!state && state == 'i.O.' ? true : false;
  }

  hasWarningState(state: string | undefined) {
    return !!state && state == 'W' ? true : false;
  }

  hasErrorState(state: string | undefined) {
    return !!state && state == 'E' ? true : false;
  }

  // getIntervalIdSliderRange(min: number, max: number) {
  //   if (this.bahn && this.bahn.intervals.length > 0) {
  //     return this.bahn.intervals.map((interval: BahnInterval) => interval.intervalId.toString());
  //   }
  //   return [];

    // while (value <= max) {
    //   array.push(value.toString());
    //   value++;
    // }

    // console.log('Array: ', array);
    // return array;
  // }

  // Use a callback function to capture the 'this' execution context of the class.
  // getIntervalIdSliderLabel(value: number) {
  //   return this.getIntervalIdSliderRange()[value];
  // };

  getIntervalIdSliderMin() {
    return 0;
  }

  getIntervalIdSliderMax() {
    return 10;
  }

  getIntervalIdSliderStepSize() {
    return 1;
  }
}
