import { Component, OnInit, ViewChild } from '@angular/core';
import { WartungenInterface } from "../../../models/wartungen.interface";
import { wartungen } from "../../domain/ressources/wartungen";
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
@Component({
  selector: 'app-wartungen',
  templateUrl: './wartungen.component.html',
  styleUrls: ['./wartungen.component.scss']
})
export class WartungenComponent implements OnInit{
  @ViewChild(DataBindingDirective) dataBinding?: DataBindingDirective;
  public gridData: WartungenInterface[] = wartungen;
  public gridView: any[] = [];

  public ngOnInit(): void {
    this.gridView = this.gridData;
  }

  public onFilter(inputValue: string): void {
    this.gridView = process(this.gridData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: this.getField,
            operator: 'contains',
            value: inputValue
          }
        ]
      }
    }).data;

    this.dataBinding? this.dataBinding.skip = 0 : null;
  }
  public getField = (args: WartungenInterface) => {
    return `${args.modell}_${args.faelligkeit}_${args.datum}_${args.pruefergebnis}_${args.status}`;
  }
}
