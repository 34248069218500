import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { Map } from 'leaflet';
import * as shapefile from 'shapefile';
import { GeoJsonObject, FeatureCollection } from 'geojson';
import { FlottenbahnService } from '../flottenbahn-v2/flottenbahn.service';

export interface SchienennetzInterface {
  abschnittsid: number;
  avg_mean_acclearation_x: number;
  avg_mean_acclearation_y: number;
  avg_mean_speed: number;
  avg_rad_aufstands_kraft_links: number;
  avg_rad_aufstands_kraft_rechts: number;
  avg_std_y: number;
  last_mean_zeitstempel: Date;
  start_lat: number;
  start_long: number;
  stop_lat: number;
  stop_long: number;
}

@Component({
  selector: 'app-schienennetz',
  templateUrl: './schienennetz.component.html',
  styleUrl: './schienennetz.component.scss'
})
export class SchienennetzComponent implements OnInit {

  public map: Map;
  schienenNetzData: any[] = [];
  schienenNetzIsLoading: boolean = true;
  schienenAbschnittData!: SchienennetzInterface[];
  schienenAbschnittIsLoading: boolean = false;
  public selectedItem!: any;
  dateInterval = {
    start: null, 
    end: null
  }

  schienenDataProps!: any[];
  activeSchienenDataProp!: any;
  barChartData: { date: string; count: number }[] = [];


  public chargeData = [
    {
      legend: 'avg_bla_links',
      stats: [
        { time: new Date('07. Okt. 15:39'), value: 10 },
        { time: new Date('08. Okt. 11:53'), value: 20 },
        { time: new Date('08. Okt. 13:31'), value: 25 },
        { time: new Date('09. Okt. 09:11'), value: 40 },
        { time: new Date('09. Okt. 10:40'), value: 50 },
        { time: new Date('09. Okt. 11:37'), value: 60 },
        { time: new Date('11. Okt. 08:52'), value: 70 },
        { time: new Date('12. Okt. 16:38'), value: 80 },
        { time: new Date('13. Okt. 19:10'), value: 100 },
      ],
    }
  ];

  apiData = Array.from({ length: 7 }, (_, i) => {
    // Zufällige Anzahl an Einträgen zwischen 3 und 8
    const numberOfEntries = Math.floor(Math.random() * 6) + 3;
  
    return Array.from({ length: numberOfEntries }, () => {
      const randomSpeed = Math.random() * 100; // Geschwindigkeit zwischen 0 und 100
      const randomAccelerationX = Math.random() * 5; // Beschleunigung X zwischen 0 und 5
      const randomAccelerationY = Math.random() * 5; // Beschleunigung Y zwischen 0 und 5
      const randomStdY = Math.random(); // Standardabweichung zwischen 0 und 1
      const randomAufstandskraftLinks = Math.random() * 50 + 20; // Aufstandskraft zwischen 20 und 70
      const randomAufstandskraftRechts = Math.random() * 50 + 20; // Aufstandskraft zwischen 20 und 70
  
      return {
        mean_zeitstempel: new Date(Date.now() - i * 24 * 60 * 60 * 1000 - Math.random() * 24 * 60 * 60 * 1000), // Zufälliger Zeitpunkt innerhalb des Tages
        mean_speed: parseFloat(randomSpeed.toFixed(2)),
        mean_acclearation_x: parseFloat(randomAccelerationX.toFixed(2)),
        mean_acclearation_y: parseFloat(randomAccelerationY.toFixed(2)),
        std_y: parseFloat(randomStdY.toFixed(3)),
        rad_aufstandskraft_links: parseFloat(randomAufstandskraftLinks.toFixed(1)),
        rad_aufstandskraft_rechts: parseFloat(randomAufstandskraftRechts.toFixed(1))
      };
    });
  }).flat() // Flach machen, um alle Einträge in einem Array zu erhalten
  .sort((a, b) => new Date(a.mean_zeitstempel).getTime() - new Date(b.mean_zeitstempel).getTime()); // Sortieren nach Datum und Uhrzeit

  constructor(private flottenbahnService: FlottenbahnService) {
  }

  ngOnInit(): void {
    this.resetSchienenDataProps();
    this.activeSchienenDataProp = this.schienenDataProps[0];
    
    // this.initMap();
    // this.addMarkers();
  }

  private initMap(data: { startDate: Date, endDate: Date }): void {
    console.log('HIER SOLLTE ES GEHEN!');
    this.schienenNetzIsLoading = false;

    if (this.map) {
      this.map.remove();
    }

    this.map = L.map('map', {
      center: [51.3400, 12.8235], // Koordinaten für Leipzig
      zoom: 11, // Zoom-Level etwas erhöht für mehr Details
      scrollWheelZoom: true, // Aktiviert Zoom mit dem Mausrad
      attributionControl: true, // Zeigt Attribution der Karte an
    });

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      attribution: '© OpenStreetMap'
    }).addTo(this.map);

    this.addMarkers(data);
  }

  filterBoxChange(data: any) {
    console.log('🔥🔥🔥 Filter Box Change: ', data);
    this.initMap(data);
    this.dateInterval = {
      start: data.startDate,
      end: data.endDate
    }
  }

  addMarkers(data: { startDate: Date, endDate: Date }) {
    this.schienenNetzIsLoading = true;
  
    let sub = this.flottenbahnService.getSchienenNetzDaten(data.startDate, data.endDate).subscribe(
      (data: any) => {
        this.schienenNetzData = data.result;
        console.log('🔥 Schienennetz Daten: ', this.schienenNetzData);
  
        this.schienenNetzData.forEach((point: any) => {
          // Extrahiere die Start- und Endkoordinaten
          const startCoordinates: [number, number] = [point.start_lat, point.start_long];
          const stopCoordinates: [number, number] = [point.stop_lat, point.stop_long];
  
          // Erstelle eine Linie mit dunklem Blau als Standardfarbe
          const polyline = L.polyline([startCoordinates, stopCoordinates], {
            color: '#00FFFF', // Farbe der Linie
            weight: 3, // Linienbreite
            opacity: 0.8, // Transparenz der Linie
          });
  
          // Füge die Linie der Karte hinzu
          polyline.addTo(this.map);
  
          // Optional: Tooltip oder Popup für die Linie hinzufügen
          polyline.bindPopup(`
            <strong>Start:</strong> [${point.start_lat}, ${point.start_long}]<br />
            <strong>Stop:</strong> [${point.stop_lat}, ${point.stop_long}]<br />
            <strong>Weitere Informationen:</strong> ${point.info || 'Keine'}
          `);
  
          // Optional: Event-Listener für Interaktion mit der Linie
          polyline.on('click', () => {
            console.log('Linie wurde angeklickt:', point);
          });
        });
  
        this.schienenNetzIsLoading = false; // Ladeanzeige beenden
        
        sub.unsubscribe();
      },
      (error) => {
        console.log('Add Markers Error: ', error);
        this.schienenNetzIsLoading = false;
        sub.unsubscribe();
      }
    );
  }

  onCellClick(event: any): void {
    console.log('Cell clicked:', event.dataItem);
    console.log('Column field:', event.column.field); // Gibt das Feld der Spalte zurück
    console.log('Cell value:', event.dataItem[event.column.field]); // Gibt den Wert der angeklickten Zelle zurück
  
    this.schienenAbschnittIsLoading = true;
    this.selectedItem = event.dataItem;
    this.getSchienenAbschnittData();
    // this.addMarkers();
  }

  getSchienenAbschnittData() {
  
    if (!!this.dateInterval.start && !!this.dateInterval.end) {
      this.flottenbahnService.getSchienenAbschnittDaten(this.selectedItem.abschnittsid, this.dateInterval.start, this.dateInterval.end).subscribe((data: any) => {
        console.log('ABSCHNITT: ', data);

        this.setSchienenDataPropsValues(this.apiData);
        this.setBarChartData();
        this.schienenAbschnittIsLoading = false;
      });
    }
  }
  
  rowCallback = (context: any) => {
    // Überprüfe, ob die aktuelle Zeile die ausgewählte ist
    return this.selectedItem === context.dataItem ? 'highlighted-row' : '';
  };

  setActiveSchienenDataProp(prop: any) {
    this.activeSchienenDataProp = prop;
  }



  resetSchienenDataProps() {
    this.schienenDataProps = [
      {
        topic: 'Speed',
        data: [
          {
            type: 'mean_speed',
            label: 'Mean Speed',
            values: []
          }
        ]
      },
      {
        topic: 'Acclearation',
        data: [
          {
            type: 'mean_acclearation_x',
            label: 'Acclearation X',
            values: []
          },
          {
            type: 'mean_acclearation_y',
            label: 'Acclearation Y',
            values: []
          }
        ]
      },
      {
        topic: 'STD',
        data: [
          {
            type: 'std_y',
            label: 'Std Y',
            values: []
          }
        ]
      },
      {
        topic: 'Rad Aufstandskraft',
        data: [
          {
            type: 'rad_aufstandskraft_links',
            label: 'Rad Aufstandskraft links',
            values: []
          },
          {
            type: 'rad_aufstandskraft_rechts',
            label: 'Rad Aufstandskraft rechts',
            values: []
          }
        ]
      }
    ];
  }
  

  setSchienenDataPropsValues(apiData: { mean_zeitstempel: Date; [key: string]: any }[]): void {
    this.schienenDataProps.forEach((topicEntry) => {
      topicEntry.data.forEach((dataEntry: any) => {
        // Filter die relevanten Daten aus dem API-Response für das aktuelle Label
        const relevantData = apiData.map((entry) => ({
          x: entry.mean_zeitstempel, // Datum
          y: entry[dataEntry.type] // Wert basierend auf dem Label
        }));
  
        // Setze die Werte in das values-Array des aktuellen Labels
        dataEntry.values = relevantData;
      });
    });
    console.log('Mapped Data Props: ', this.schienenDataProps);
  }

  prepareBarChartData(data: { x: Date; y: number }[]): { date: string; count: number }[] {
    const countsByDate: { [key: string]: number } = {}; // Ein Objekt, um die Zählungen nach Datum zu speichern
  
    // Durchlaufe die Daten und aktualisiere die Zählungen
    data.forEach(entry => {
      const dateKey: string = new Date(entry.x).toISOString().split('T')[0]; // Extrahiere das Datum (YYYY-MM-DD)
      countsByDate[dateKey] = (countsByDate[dateKey] || 0) + 1; // Erhöhe den Zähler für das Datum
    });
  
    // Erstelle eine Liste aller Tage im vollständigen Bereich
    const dates: Date[] = Object.keys(countsByDate).map(date => new Date(date));
    const minDate: Date = new Date(Math.min(...dates.map(d => d.getTime())));
    const maxDate: Date = new Date(Math.max(...dates.map(d => d.getTime())));
  
    const fullDateRange: { date: string; count: number }[] = [];
    let currentDate: Date = new Date(minDate);
  
    // Gehe durch den vollständigen Datumsbereich und füge die Zählungen hinzu
    while (currentDate <= maxDate) {
      const dateKey: string = currentDate.toISOString().split('T')[0];
      fullDateRange.push({
        date: dateKey,
        count: countsByDate[dateKey] || 0, // Füge 0 hinzu, wenn kein Eintrag vorhanden ist
      });
      currentDate.setDate(currentDate.getDate() + 1); // Gehe zum nächsten Tag
    }
  
    return fullDateRange;
  }

  setBarChartData() {
    // Daten aus dem Scatter-Line-Diagramm extrahieren
    const rawData = this.activeSchienenDataProp.data.flatMap((prop: any) => prop.values);
    
    // Daten für das Balkendiagramm vorbereiten
    this.barChartData = this.prepareBarChartData(rawData);
    console.log('Bar Chart Data: ', rawData);
  }

  getLineColor(index: number): string {
    // Definiere benutzerdefinierte Farben
    const colors = ['#FF5733', '#33C1FF']; // Farbe 1: Rot, Farbe 2: Blau
    return colors[index % colors.length]; // Zyklische Auswahl, falls mehr Linien hinzukommen
  }
}
