import { WartungenInterface } from "../../../models/wartungen.interface";

export const wartungen: WartungenInterface[] = [
  {
    modell: 'DBM-134-M45',
    faelligkeit: '02/23',
    datum: '14.02.2023',
    pruefergebnis: 'alles i.O.',
    status: 'in Betrieb'
  },
  {
    modell: 'DBM-134-M31',
    faelligkeit: '04/23',
    datum: '24.02.2023',
    pruefergebnis: '-',
    status: 'in Betrieb'
  },
  {
    modell: 'DBM-134-M43',
    faelligkeit: '08/23',
    datum: '',
    pruefergebnis: '-',
    status: 'in Betrieb'
  },
  {
    modell: 'DBM-23-M12',
    faelligkeit: '05/23',
    datum: '',
    pruefergebnis: '',
    status: 'in Betrieb'
  },
  {
    modell: 'DBM-167-M40',
    faelligkeit: '01/23',
    datum: '17.01.2023',
    pruefergebnis: 'Reparaturen nötig',
    status: 'Defekt'
  },
  {
    modell: 'DBM-79-M142',
    faelligkeit: '12/22',
    datum: '08.12.2022',
    pruefergebnis: 'alles i.O.',
    status: 'in Betrieb'
  },
  {
    modell: 'DBM-134-M145',
    faelligkeit: '12/22',
    datum: '01.12.2022',
    pruefergebnis: 'alles i.O.',
    status: 'in Betrieb'
  },
  {
    modell: 'DBM-134-M31',
    faelligkeit: '11/22',
    datum: '29.11.2022',
    pruefergebnis: 'kleine Mängel behoben',
    status: 'in Betrieb'
  },
  {
    modell: 'DBM-134-M43',
    faelligkeit: '12/22',
    datum: '06.12.2022',
    pruefergebnis: 'alles i.O.',
    status: 'in Betrieb'
  },
  {
    modell: 'DBM-23-M12',
    faelligkeit: '01/22',
    datum: '19.01.2022',
    pruefergebnis: 'Reparaturen nötig',
    status: 'Defekt'
  },
  {
    modell: 'DBM-167-M40',
    faelligkeit: '03/23',
    datum: '17.03.2023',
    pruefergebnis: '-',
    status: 'in Wartung'
  },
  {
    modell: 'DBM-79-M142',
    faelligkeit: '07/23',
    datum: '',
    pruefergebnis: '',
    status: 'in Betrieb'
  }
];
