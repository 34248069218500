import {Component} from '@angular/core'
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  public loginUser() {
    window.location.href = environment.authUrl + 'login';
  }
}
