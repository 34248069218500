import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { AppRoutingModule } from "./app-routing.module";
import { CardModule, LayoutModule, } from '@progress/kendo-angular-layout';
import { NavigationModule } from "@progress/kendo-angular-navigation";
import { IconsModule } from "@progress/kendo-angular-icons";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { FlotteComponent } from './components/flotte/flotte.component';
import { RailstateComponent } from './components/railstate/railstate.component';
import { WartungenComponent } from './components/wartungen/wartungen.component';
import { ReferenzbahnComponent } from './components/referenzbahn/referenzbahn.component';
import { AboutComponent } from './components/about/about.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from "@progress/kendo-angular-inputs";
import { GeplanteMessungenComponent } from './components/railstate/geplante-messungen/geplante-messungen.component';
import { DurchgefuehrteMessungenComponent } from './components/railstate/durchgefuehrte-messungen/durchgefuehrte-messungen.component';
import { ErgebnisseComponent } from './components/railstate/ergebnisse/ergebnisse.component';
import { DialogModule } from "@progress/kendo-angular-dialog";
import { FormsModule, ReactiveFormsModule  } from "@angular/forms";
import { LabelModule } from "@progress/kendo-angular-label";
import { NeueMessungComponent } from './components/railstate/neue-messung/neue-messung.component';
import { LoaderComponent } from './components/loader/loader.component';
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { ProgressBarModule } from "@progress/kendo-angular-progressbar";
import { TreeViewModule } from "@progress/kendo-angular-treeview";
import { MenusModule } from "@progress/kendo-angular-menu";
import { ApixuService } from "./domain/services/apixu.service";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import {LoginComponent} from "./main/login/login.component";
import { FlottenbahnComponent } from './components/flottenbahn/flottenbahn.component';
import { WeatherService } from './domain/services/weather.service';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import '@progress/kendo-angular-intl/locales/de/all';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { FlottenbahnV2Component } from './components/flottenbahn-v2/flottenbahn-v2.component';
import { DoppeltuerenComponent } from './components/flottenbahn-v2/doppeltueren/doppeltueren.component';
import { DatePipe } from '@angular/common';
import { IndicatorsModule } from "@progress/kendo-angular-indicators";
import { RouterModule, Routes } from '@angular/router';
import { PolygonisierungComponent } from "./components/flottenbahn-v2/polygonisierung/polygonisierung.component";
import { VerortungComponent } from "./components/flottenbahn-v2/verortung/verortung.component";
import { SchienennetzComponent } from './components/schienennetz/schienennetz.component';
import { ElektronikComponent } from './components/elektronik/elektronik.component';
import { ChartModule } from '@progress/kendo-angular-charts';
import { FilterboxComponent } from './components/filterbox/filterbox.component';

registerLocaleData(localeDe);

const routes: Routes = [
  // Deine Routenkonfigurationen hier
];

@NgModule({ bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        HeaderComponent,
        DashboardComponent,
        FlotteComponent,
        RailstateComponent,
        WartungenComponent,
        ReferenzbahnComponent,
        AboutComponent,
        GeplanteMessungenComponent,
        DurchgefuehrteMessungenComponent,
        ErgebnisseComponent,
        NeueMessungComponent,
        LoginComponent,
        FlottenbahnComponent,
        UserMenuComponent,
        FlottenbahnV2Component,
        DoppeltuerenComponent,
        PolygonisierungComponent,
        VerortungComponent,
        LoaderComponent,
        SchienennetzComponent,
        ElektronikComponent,
        FilterboxComponent
    ],
    exports: [RouterModule], 
    imports: [RouterModule.forRoot(routes, {
            canceledNavigationResolution: 'replace',
            paramsInheritanceStrategy: 'always',
        }),
        IndicatorsModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        LayoutModule,
        NavigationModule,
        IconsModule,
        ButtonsModule,
        GridModule,
        InputsModule,
        DialogModule,
        FormsModule,
        ReactiveFormsModule,
        LabelModule,
        DateInputsModule,
        ChartsModule,
        ProgressBarModule,
        TreeViewModule,
        CardModule,
        MenusModule,
        DropDownsModule,
        ChartModule],
    providers: [
        WeatherService,
        { provide: LOCALE_ID, useValue: 'de' },
        DatePipe,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
