<div class="elektronik-container">
    <app-loader *ngIf="!data"></app-loader>
    <div *ngIf="data" class="elektronik-chart">
        <kendo-chart>
            <kendo-chart-title text="Anomalien"></kendo-chart-title>
            <kendo-chart-legend position="right" orientation="vertical">
            </kendo-chart-legend>
            <kendo-chart-series>
                <kendo-chart-series-item
                    [data]="data"
                    name="Temperatur"
                    yField="anomalieTemperaturNumber"
                    xField="timestamp"
                    [markers]="{ visible: true }"
                    type="scatter">
                </kendo-chart-series-item>
                <kendo-chart-series-item
                    [data]="data"
                    name="Strom"
                    yField="anomalieStromNumber"
                    xField="timestamp"
                    [markers]="{ visible: true }"
                    type="scatter"
                    color="#33C1FF">
                </kendo-chart-series-item>
            </kendo-chart-series>
            <kendo-chart-x-axis>
                <kendo-chart-x-axis-item>
                    <kendo-chart-x-axis-item-labels format="{0:dd.MM. HH:mm}"></kendo-chart-x-axis-item-labels>
                </kendo-chart-x-axis-item>
            </kendo-chart-x-axis>
            <kendo-chart-y-axis>
                <kendo-chart-y-axis-item [min]="0" [max]="3" [labels]="{ content: formatYAxisLabel }">
                </kendo-chart-y-axis-item>
            </kendo-chart-y-axis>
            <kendo-chart-series-item-tooltip background="#5a80b2">
                <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-category="category" let-dataItem="dataItem">
                  <span>
                  </span>
                </ng-template>
            </kendo-chart-series-item-tooltip>
        </kendo-chart>
    </div>
    <div *ngIf="data" class="elektronik-chart">
        <kendo-chart>
            <kendo-chart-title text="Restlebensdauer"></kendo-chart-title>
            <kendo-chart-legend position="right" orientation="vertical">
            </kendo-chart-legend>
            <kendo-chart-series>
                <div>
                    <kendo-chart-series-item
                        [data]="bauteile"
                        yField="bauteil"
                        xField="timestamp"
                        [markers]="{ visible: true }"
                        type="scatter">
                    </kendo-chart-series-item>
                </div>
            </kendo-chart-series>
            <kendo-chart-x-axis>
                <kendo-chart-x-axis-item>
                    <kendo-chart-x-axis-item-labels format="{0:dd.MM. HH:mm}"></kendo-chart-x-axis-item-labels>
                </kendo-chart-x-axis-item>
            </kendo-chart-x-axis>
            <kendo-chart-y-axis>
                <!-- <kendo-chart-y-axis-item min="0" max="1"> -->
                <kendo-chart-y-axis-item>
                </kendo-chart-y-axis-item>
            </kendo-chart-y-axis>
            <kendo-chart-series-item-tooltip background="#5a80b2">
                <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-category="category" let-dataItem="dataItem">
                  <span>
                  </span>
                </ng-template>
            </kendo-chart-series-item-tooltip>
        </kendo-chart>
    </div>
</div>