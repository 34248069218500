import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { FlottenbahnService } from '../flottenbahn.service';
import { AxisLabelContentArgs } from '@progress/kendo-angular-charts';
import { IntlService } from '@progress/kendo-angular-intl';

@Component({
  selector: 'app-polygonisierung',
  templateUrl: './polygonisierung.component.html',
  styleUrl: './polygonisierung.component.scss'
})
export class PolygonisierungComponent implements OnInit {
  @HostBinding('style.outline') outline = 'none';
  @HostBinding('style.border') border = 'none';
  @Input() public data!: any;
  @Input() public bahnId!: any;
  @Input() public start!: any;
  @Input() public end!: any;
  firstLineChartIsLoading: boolean = false;
  secondLineChartIsLoading: boolean = false;
  firstLineChartLabel = 'Schädigungs-D';
  secondLineChartLabel = 'Polygontiefe in Millimeter';
  secondLineChartYMin!: number;
  lineChart = {
    data: [],
    xAxisLabel: '',
    yAxisLabel: ''
  }
  lineChartTransformed: { data: {x: string, y: number}[] | [], xAxisLabel: string, yAxisLabel: string, yAxisMin: number, yAxisMax: number } = {
    data: [],
    xAxisLabel: '',
    yAxisLabel: '',
    yAxisMin: 0,
    yAxisMax: 1
  }
  yExpoWert: number = 1;

  selectedCell: any = null;
  heatmapData!: any;

  constructor(private flottenbahnService: FlottenbahnService, private intl: IntlService) {}

  ngOnInit(): void {
    this.drawHeatmap();
  }

  getCellColor = (point: any) => {
    if (
      this.selectedCell &&
      this.selectedCell.x === point.value.x &&
      this.selectedCell.y === point.value.y
    ) {
      // Markiere die ausgewählte Zelle in hellblau
      return '#5a80b2';
    }
  
    // Berechne Min- und Max-Wert aller Zellen
    const values = this.data.result.values_array.map((v: any) => v[2]);
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
  
    // Normalisiere den Wert, wobei wir den Bereich für negative und positive Werte berücksichtigen
    let normalizedValue: number;
  
    if (point.value.value >= 0) {
      // Für Werte >= 0 normalisieren wir zwischen 0 und maxValue
      normalizedValue = point.value.value / Math.max(maxValue, 1); // Vermeide Division durch 0
    } else {
      // Für Werte < 0 normalisieren wir zwischen minValue und 0
      normalizedValue = point.value.value / Math.abs(minValue); // Werte liegen im Bereich [-1, 0]
    }
  
    // Berechne den Farbwert basierend auf dem normalisierten Wert
    return this.interpolateColor(normalizedValue);
  };
  
  
  interpolateColorOld = (value: number) => {
    const positiveStartColor = [225, 175, 175]; // Gelb für Werte nahe 0
    const positiveEndColor = [255, 0, 0]; // Rot für Werte >= 1
  
    const negativeStartColor = [144, 238, 144]; // Hellgrün für Werte nahe 0
    const negativeEndColor = [0, 100, 0]; // Dunkelgrün für Werte nahe -1
  
    let color;
  
    if (value >= 0) {
      // Positive Werte: Interpoliere zwischen Gelb und Rot
      const ratio = Math.min(value, 1); // Begrenze auf maximal 1
      color = [
        Math.round(positiveStartColor[0] + ratio * (positiveEndColor[0] - positiveStartColor[0])),
        Math.round(positiveStartColor[1] + ratio * (positiveEndColor[1] - positiveStartColor[1])),
        Math.round(positiveStartColor[2] + ratio * (positiveEndColor[2] - positiveStartColor[2]))
      ];
    } else {
      // Negative Werte: Interpoliere zwischen Hellgrün und Dunkelgrün
      const ratio = Math.min(Math.abs(value), 1); // Begrenze auf maximal 1
      color = [
        Math.round(negativeStartColor[0] + ratio * (negativeEndColor[0] - negativeStartColor[0])),
        Math.round(negativeStartColor[1] + ratio * (negativeEndColor[1] - negativeStartColor[1])),
        Math.round(negativeStartColor[2] + ratio * (negativeEndColor[2] - negativeStartColor[2]))
      ];
    }
  
    // Konvertiere das Array in eine RGB-Farbe
    return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
  };
  
  interpolateColor = (value: number) => {
    // Farbdefinitionen
    const red = [255, 0, 0]; // Rot
    const orange = [255, 165, 0]; // Orange
    const yellow = [255, 255, 0]; // Gelb
    const gray = [169, 169, 169]; // Hellgrau
    const lightGreen = [144, 238, 144]; // Hellgrün
    const darkGreen = [0, 100, 0]; // Dunkelgrün
  
    let color;
  
    if (value > 0) {
      if (value >= 0.66) {
        // Übergang von Orange zu Rot (hohe positive Werte)
        const ratio = (value - 0.66) / (1 - 0.66);
        color = [
          Math.round(orange[0] + ratio * (red[0] - orange[0])),
          Math.round(orange[1] + ratio * (red[1] - orange[1])),
          Math.round(orange[2] + ratio * (red[2] - orange[2])),
        ];
      } else if (value >= 0.33) {
        // Übergang von Gelb zu Orange (mittlere positive Werte)
        const ratio = (value - 0.33) / (0.66 - 0.33);
        color = [
          Math.round(yellow[0] + ratio * (orange[0] - yellow[0])),
          Math.round(yellow[1] + ratio * (orange[1] - yellow[1])),
          Math.round(yellow[2] + ratio * (orange[2] - yellow[2])),
        ];
      } else {
        // Übergang von Grau zu Gelb (Werte knapp über 0)
        const ratio = value / 0.33;
        color = [
          Math.round(gray[0] + ratio * (yellow[0] - gray[0])),
          Math.round(gray[1] + ratio * (yellow[1] - gray[1])),
          Math.round(gray[2] + ratio * (yellow[2] - gray[2])),
        ];
      }
    } else {
      const ratio = Math.abs(value) / 0.1;
      color = [
        Math.round(lightGreen[0] + ratio * (lightGreen[0] - gray[0])),
        Math.round(lightGreen[1] + ratio * (lightGreen[1] - gray[1])),
        Math.round(lightGreen[2] + ratio * (lightGreen[2] - gray[2])),
      ];
    }
  
    // Konvertiere das Array in eine RGB-Farbe
    return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
  };
  
  

  drawHeatmap() {
    this.heatmapData = this.data.result.values_array.map((item: any) => ({
      x: item[0],     // Ordnung
      y: item[1],     // Rad
      value: this.formatToFiveDigits(item[2]) > 0 ? this.formatToFiveDigits(item[2]) : item[2]  // Wert
    }));
  }

  // Event-Handler für das Click-Event einer Zelle
  onCellClick(event: any) {
    this.firstLineChartIsLoading = true;

    const clickedCell = event.dataItem; // Holen Sie sich das Datenobjekt der angeklickten Zelle
    this.selectedCell = {
      x: clickedCell.x,
      y: clickedCell.y,
      value: clickedCell.value
    };
    console.log('Ausgewählte Zelle:', this.selectedCell);

    this.drawHeatmap();
    this.getPolygonisierungLinechart();
  }

  getExpoChartYMin(): number {
    if (!this.lineChart.data || this.lineChart.data.length === 0) {
      return 0.1; // Fallback-Wert für leere Daten
    }
  
    const yValues = this.lineChart.data
      .map((element: any) => Number(element.yExpoWert))
      .filter((value: number) => !isNaN(value)); // Filtert ungültige Zahlen aus
  
    if (yValues.length === 0) {
      return 0.1; // Fallback-Wert, falls keine gültigen Werte vorliegen
    }
  
    return Math.min(...yValues) / 10; // Minimum berechnen und durch 10 teilen
  }

  getPolygonisierungLinechart() {

    this.flottenbahnService.getPolygonisierungLinechart(this.bahnId, this.start, this.end, this.selectedCell.x.toString(), this.selectedCell.y.toString(), this.yExpoWert).subscribe((data: any) => {
      console.log('Polygonisierung Linechart: ', data);

      const values = data.result.values;

      // Mappe die Werte in ein passendes Format für die Chart
      const chartData = values.map((item: any) => ({
        x: new Date(item.zeit),  // Verwende den Zeitwert als X-Wert (Zeitachse)
        // y: this.formatToFiveDigits(item.wert),             // Verwende den Wert als Y-Wert
        y: item.wert,             // Verwende den Wert als Y-Wert
        // yExpoWert: item.expo_wert_Log, // XXX: Wert logarithmisch anzeigen
        yExpoWert: item.expo_wert, // XXX: Wert nicht logarithmisch anzeigen
        tooltip: 'Datum: ' + this.formatDate(new Date(item.zeit)) + ', Wert: ' + this.formatToFiveDigits(item.wert),
        tooltip2: 'Test 123..'
        // yExpoWert: this.formatToFiveDigits(item.expo_wert) > 0 ? this.formatToFiveDigits(Math.log10(item.expo_wert)) : Math.log10(item.expo_wert)
      }));

      // Setze die Chartdaten auf das gemappte Array
      this.lineChart.data = chartData;
      this.secondLineChartYMin = this.getExpoChartYMin();

      console.log('Chart Data: ', chartData);

      // Optional: Passe Meta-Informationen an, wie Labels etc.
      this.lineChart.xAxisLabel = data.result.metainfo.x_label || 'Zeit';
      this.lineChart.yAxisLabel = data.result.metainfo.y_labels[0].label || 'Wert';

      this.setTransformedData();

      this.firstLineChartIsLoading = false;
    });
  }

  formatDate(value: Date): string {
    return this.intl.formatDate(value, 'dd. MMM yyyy, HH:mm');
  }

  setTransformedData() {
    // Finde den minimalen und maximalen Wert von yExpoWert im lineChart.data-Array
    const yValues = this.lineChart.data.map((item: any) => item.yExpoWert);
    const yMin = Math.min(...yValues);
    const yMax = Math.max(...yValues);
  
    // Berechne die Differenz zwischen dem maximalen und minimalen Wert
    const yDiff = yMax - yMin;
  
    // Berechne yAxisMin und yAxisMax mit moderatem Puffer
    let yAxisMin = yMin - yDiff / 5;
    let yAxisMax = yMax + yDiff / 5;

    // Sicherstellen, dass yAxisMin nicht unter 1 fällt
    if (yAxisMin < 1 && yMin >= 1) {
      yAxisMin = 1;
    }
  
    // Sicherstellen, dass yAxisMin und yAxisMax sinnvoll gerundet sind
    const decimalPlaces = Math.max(0, -Math.floor(Math.log10(yDiff)));
    yAxisMin = Math.floor(yAxisMin * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
    yAxisMax = Math.ceil(yAxisMax * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
  
    // Aktualisiere die lineChartTransformed-Variable mit den neuen Werten
    this.lineChartTransformed = {
      data: this.lineChart.data,
      xAxisLabel: this.lineChart.xAxisLabel,
      yAxisLabel: this.lineChart.yAxisLabel,
      yAxisMin: yAxisMin,
      yAxisMax: yAxisMax
    };
  
    // Optional: Logge die berechneten Werte zur Überprüfung
    console.log('🔥🔥 yAxisMin:', yAxisMin, 'yAxisMax:', yAxisMax, 'Rundung auf Dezimalstellen:', decimalPlaces);
  }
  

  yAxisLabelContent(e: any): string {
    return `Ordnung ${e.value}`;
  }

  xAxisLabelContent(e: any): string {
    return `Rad ${e.value}`;
  }

  onChangeXAxisMultiplicator(value: number) {
    console.log('Multiplikator: ', value);
    this.yExpoWert = !!value && value > 0 ? value : 1;
    // this.setTransformedData();
    this.getPolygonisierungLinechart();
  }

  allowOnlyNumbers(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    // Nur Ziffern und Steuerzeichen wie Backspace erlauben
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  }

  formatToFiveDigits(num: number): number {
    return this.flottenbahnService.formatToFiveDigits(num);
  }

  formatNumber(value: number): string {
    // Begrenze auf 5 Dezimalstellen und wandle in wissenschaftliche Notation um, falls notwendig
    return value.toExponential(5);
  }

  formatYAxisLabel(e: AxisLabelContentArgs): string {
    const value = e.value;
  
    if (value < 1) {
      return value.toExponential(1); // Werte unter 1 im wissenschaftlichen Format anzeigen
    }
  
    return value.toFixed(1); // Werte >= 1 auf eine Nachkommastelle runden
  }

}
