import { Component, HostListener, Input } from '@angular/core';
import { UserService } from 'src/app/domain/services/user.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent {

  @Input() public isLogin?: boolean = false;
  userProfileIsOpen: boolean = false;
  logoutDialogIsOpen: boolean = false;
  userData: any;

  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent): void {
    const dialog = document.querySelector('.k-dialog');
    const clickedInside = dialog?.contains(event.target as Node);
    if (this.isAnyDialogOpen() && !clickedInside) {
      this.closeDialogs();
    }
  }

  constructor(private userService: UserService) {
    userService.getUser().subscribe((data: any) => {
      this.userData = data;
    });
  }

  getUserData() {
    if (!!this.userData && !!this.userData.body) {
      return {
        user: this.userData.body.display_name,
        mail: this.userData.body.preferred_username
      };
    }
    return null;
  }

  closeDialogs() {
    this.closeLogoutDialog();
    this.closeUserProfile();
  }

  isAnyDialogOpen() {
    return this.userProfileIsOpen || this.logoutDialogIsOpen ? true : false;
  }

  openUserProfile() {
    setTimeout(() => {
      this.userProfileIsOpen = true;
    } , 100);
  }

  closeUserProfile() {
    this.userProfileIsOpen = false;
  }

  openLogoutDialog() {
    setTimeout(() => {
      this.logoutDialogIsOpen = true;
    } , 100);
  }

  closeLogoutDialog() {
    this.logoutDialogIsOpen = false;
  }

  logoutUser() {
    this.userService.logoutUser();
  }

  onSelect(event: any) {
    if (event.item.text === 'Profil') {
      this.openUserProfile();
    } else if (event.item.text === 'Abmelden') {
      this.openLogoutDialog();
    }
  }
}
