<div class="header header-bg">
  <div class="nav-container">
    <div class="lrv-lrv_logo"></div>
    <div class="title">
      <div class="title-white">Mein Tram-Dashboard</div>
    </div>
    <a *ngIf="isLogin" routerLink="/about" class="lrv-help"></a>
    <app-user-menu [isLogin]="isLogin"></app-user-menu>
  </div>
</div>
