
import { ProblematikInterface } from "../../../models/problematik.interface";

export const problematik: ProblematikInterface[] = [
  {
    kritikalitaet: 'schwer',
    bauteil: 'Antrieb',
    fzg_nr: 'TR232DVB424',
    gemeldet: '13.03.2023'
  },
  {
    kritikalitaet: 'schwer',
    bauteil: 'Türen',
    fzg_nr: 'TR232DVB258',
    gemeldet: '12.03.2023'
  },
  {
    kritikalitaet: 'mittel',
    bauteil: 'Stromrichter',
    fzg_nr: 'TR232DVB123',
    gemeldet: '11.03.2023'
  },
  {
    kritikalitaet: 'mittel',
    bauteil: 'Antrieb',
    fzg_nr: 'TR232DVB454',
    gemeldet: '10.03.2023'
  },
  {
    kritikalitaet: 'leicht',
    bauteil: 'Türen',
    fzg_nr: 'TR232DVB478',
    gemeldet: '08.03.2023'
  },

];
