<!--a href="https://backend-dev.lrvtwin.de/auth/login" >Click me!</a-->
<div class="card-container-about">
  <div class="card-header-wrapper">
    <div id="box1">
      <div id="box2">
        <img alt="login" src="./assets/images/lrv-twin.jpg" height="400px"/>
        <button kendoButton (click)="loginUser()" class="login">Anmelden</button>
      </div>
    </div>
  </div>
</div>

