import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-doppeltueren',
  templateUrl: './doppeltueren.component.html',
  styleUrls: ['./doppeltueren.component.scss']
})
export class DoppeltuerenComponent implements OnInit {

  @Input() public data!: any;
  tableData!: any;
  chartDataMotorzustand!: any;
  chartDataRiemenSpannung!: any;
  chartDataOpenings!: any;
  chartDataErrorOpenings!: any;
  selectedChartType = 'motorzustand';
  chartTitle = 'Systemzustand vs. Zeit';
  yAxisTitle = 'Systemzustand';
  totalOpenings = {
    openings: 0,
    erroropenings: 0
  }

  constructor(private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    console.log('Test: ', this.data);

    this.tableData = this.getData();
    this.chartDataMotorzustand = this.getChartData('motorzustand');
    this.chartDataRiemenSpannung = this.getChartData('riemen_spannung');
    this.chartDataOpenings = this.getChartData('openings');
    this.chartDataErrorOpenings = this.getChartData('erroropenings');
  }

  setChartType(type: string) {
    this.selectedChartType = type;
    switch (type) {
      case 'motorzustand':
        this.chartTitle = 'Systemzustände über die Zeit';
        this.yAxisTitle = 'Systemzustand';
        break;
      case 'riemen_spannung':
        this.chartTitle = 'Riemenspannung vs. Zeit';
        this.yAxisTitle = 'Riemenspannung';
        break;
      case 'openings':
        this.chartTitle = 'Anzahl Türöffnungen & Missbrauchsereignisse';
        this.yAxisTitle = 'Anzahl';
        break;
    }
  }

  getChartData(type: string) {
    return this.data.result.values.map((entry: any) => {
      return {
        endzeit: new Date(entry.endzeit),
        motorzustand: entry.motorzustand,
        riemen_spannung: entry.riemen_spannung,
        openings: entry.openings,
        erroropenings: entry.erroropenings
      };
    });
  }

  getData() {
    return this.data.result.values.map((data: any) => ({
      ...data,
      endzeit: this.datePipe.transform(data.endzeit, 'dd. MMMM, HH:mm')
    }));
  }

  formatYAxisLabel = (e: any): string => {
    if (e.value === 1) {
      return 'In Ordnung';
    } else if (e.value === 2) {
      return 'Kritisch';
    }
    return ''; // Leere Labels für Werte außerhalb des Bereichs
  };

  // formatDate(date: Date | string): string | null {
  //   return this.datePipe.transform(date, 'dd. MMMM, HH:mm');
  // }

  // getMotorzustand() {
  //   return this.data.result.values.map((data: any) => ({
  //     motorzustand: data.motorzustand
  //   }));
  // }

  // getChartOptions() {
  //   return this.data.result.metainfo.y_labels ?? [];
  // }
}
