import { BahnInterface } from "../../../models/bahn.interface";

export const bahnzustand: BahnInterface[] = [
  {
    bahn: '1',
    zustand_gesamt: 'E',
    zustand_sr_antrieb: 'W',
    zustand_sr_raeder: 'i.O.',
    zustand_tueren:  'E',
    bauteilzustand: 'Kritisch'
  },
  {
    bahn: '2',
    zustand_gesamt: 'i.O.',
    zustand_sr_antrieb: 'i.O.',
    zustand_sr_raeder: 'i.O.',
    zustand_tueren:  'i.O.',
    bauteilzustand: 'i.O.'
  },
  {
    bahn: '3',
    zustand_gesamt: 'i.O.',
    zustand_sr_antrieb: 'i.O.',
    zustand_sr_raeder: 'i.O.',
    zustand_tueren:  'i.O.',
    bauteilzustand: 'i.O.'
  },
  {
    bahn: '4',
    zustand_gesamt: 'i.O.',
    zustand_sr_antrieb: 'i.O.',
    zustand_sr_raeder: 'i.O.',
    zustand_tueren:  'i.O.',
    bauteilzustand: 'i.O.'
  },
  {
    bahn: '5',
    zustand_gesamt: 'i.O.',
    zustand_sr_antrieb: 'i.O.',
    zustand_sr_raeder: 'i.O.',
    zustand_tueren:  'i.O.',
    bauteilzustand: 'i.O.'
  },
  {
    bahn: '6',
    zustand_gesamt: 'W',
    zustand_sr_antrieb: 'W',
    zustand_sr_raeder: 'i.O.',
    zustand_tueren:  'i.O.',
    bauteilzustand: 'i.O.'
  },
  {
    bahn: '7',
    zustand_gesamt: 'i.O.',
    zustand_sr_antrieb: 'i.O.',
    zustand_sr_raeder: 'i.O.',
    zustand_tueren:  'i.O.',
    bauteilzustand: 'i.O.'
  }
]

