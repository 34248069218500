<div class="grid-layout-container">
  <kendo-gridlayout
    [gap]="{ rows: 12, cols: 12 }"
  >

    <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="2" [rowSpan]="1">
      <div class="grid-container tram-container" (click)="onReferenzBahnPictureClick()">
        <div class="tram-tile-title">Referenzbahn</div>
      </div>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item [row]="1" [col]="3" [colSpan]="2" [rowSpan]="1">
      <div class="grid-container">
        <div class="tile-title-center">Top-Down akute Problematik</div>
        <kendo-grid
          [kendoGridBinding]="gridView"
          [pageSize]="10"
          [pageable]="true"
          [sortable]="true"
          id="problematik-grid"
          style="margin:10px;height:auto;"
        >
          <kendo-grid-column
            field="kritikalitaet"
            [title]="'Kritikaliät'"
          >
            <ng-template kendoGridCellTemplate let-dataItem>

              <span *ngIf="dataItem.kritikalitaet == 'leicht'"
                    class="badge badge-betrieb">{{ dataItem.kritikalitaet }} </span>
              <span *ngIf="dataItem.kritikalitaet == 'schwer'"
                    class="badge badge-defekt">{{ dataItem.kritikalitaet }} </span>
              <span *ngIf="dataItem.kritikalitaet == 'mittel'"
                    class="badge badge-wartung">{{ dataItem.kritikalitaet }} </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            field="bauteil"
            [title]="'Bauteil'"
          >
          </kendo-grid-column>
          <kendo-grid-column
            field="fzg_nr"
            [title]="'Fahrzeugnummer'"
          >
          </kendo-grid-column>
          <kendo-grid-column
            field="gemeldet"
            [title]="'gemeldet'"
          >
          </kendo-grid-column>
        </kendo-grid>
      </div>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1" [rowSpan]="1">
      <div class="grid-container weather-container">
        <div class="status-temp">
          <div class="box-green" [ngClass]="{ 'status-green': weatherData, 'status-red': !weatherData }"></div>
          <div class="status-value">{{ getCurrentWeatherStatus() }}</div>
        </div>
        <div class="tile">
          <div>
              <span class="lrv-temperature">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
                <span class="path7"></span>
                <span class="path8"></span>
              </span>
          </div>
          <div class="daten">
            <div class="text-titel">Akt. Temperatur</div>
            <div *ngIf="weatherData" class="text-value">{{ weatherData?.temperature }}°C</div>
            <div *ngIf="!weatherData" class="text-value">-</div>
          </div>
        </div>
        <div *ngIf="weatherData" class="time-info">Stand: {{ weatherData?.temperatureTime | date:'dd. MMMM, HH:mm' }} Uhr</div>
      </div>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item [row]="2" [col]="2" [colSpan]="1" [rowSpan]="1">
      <div class="grid-container weather-container">
        <div class="status-temp">
          <div class="box-green" [ngClass]="{ 'status-green': weatherData, 'status-red': !weatherData }"></div>
          <div class="status-value">{{ getCurrentWeatherStatus() }}</div>
        </div>
        <div class="tile">
          <div class="">
              <span class="lrv-humidity">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
                <span class="path7"></span>
                <span class="path8"></span>
              </span>
          </div>
          <div class="daten">

            <div class="text-titel">Rel. Luftfeuchte</div>
            <div *ngIf="weatherData" class="text-value">{{ weatherData?.humidity }}%</div>
            <div *ngIf="!weatherData" class="text-value">-</div>
          </div>
        </div>
        <div *ngIf="weatherData" class="time-info">Stand: {{ weatherData?.humidityTime | date:'dd. MMMM, HH:mm' }} Uhr</div>
      </div>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item [row]="2" [col]="3" [colSpan]="2" [rowSpan]="1">
      <div class="grid-container">
        <div class="tile-title-center">Flotte im Überblick</div>
        <div class="flotte-tile">
          <div>
            <span class="lrv-fleet-overview"></span>
          </div>
          <div class="flotte-values">
            <div class="flotte">
              <div class="flotte-text">Anzahl der Standardbahnen Leipzig:</div>
              <div class="flotte-value">9</div>
            </div>
            <div class="flotte">
              <div class="flotte-text">Anzahl der Standardbahnen Dresden:</div>
              <div class="flotte-value">1</div>
            </div>
          </div>
        </div>
      </div>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
</div>

