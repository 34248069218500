<div class="filter-box">
    <form class="filter-box-form" [formGroup]="filterBoxForm" (ngSubmit)="onSubmitFilterBox($event)">

      <div class="filter-box-form-field-container">
        <div class="filter-box-form-field">

          <kendo-daterange>

            <kendo-floatinglabel text="Start-Datum:">
              <kendo-dateinput
                kendoDateRangeStartInput
                formControlName="startDate"
                [value]="filterBoxForm.value.startDate ?? null"
              ></kendo-dateinput>

            </kendo-floatinglabel>
            <kendo-floatinglabel text="Start-Zeit:">

              <kendo-timepicker
                [value]="filterBoxForm.value.startDate ?? null"
                formControlName="startDate"
              ></kendo-timepicker>

            </kendo-floatinglabel>

            <div class="divider">
              <div class="line"></div>
            </div>

            <kendo-floatinglabel text="End-Datum:">

              <kendo-dateinput
                kendoDateRangeEndInput
                formControlName="endDate"
                [value]="filterBoxForm.value.endDate ?? null"
              ></kendo-dateinput>
            </kendo-floatinglabel>
            <kendo-floatinglabel text="End-Zeit:">

              <kendo-timepicker
                [value]="filterBoxForm.value.endDate ?? null"
                formControlName="endDate"
              ></kendo-timepicker>

            </kendo-floatinglabel>
          </kendo-daterange>

        </div>
      </div>
      
      <button [disabled]="!filterBoxHasChanges()" kendoButton type="submit">
        {{ filterBoxHasChanges() ? 'Auswahl bestätigen' : 'Auswahl bestätigt' }}
      </button>
    </form>
</div>