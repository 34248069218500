import { Component } from '@angular/core';
import { LoaderSize, LoaderThemeColor, LoaderType } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent {

  public loader = {
    type: <LoaderType>"converging-spinner",
    themeColor: <LoaderThemeColor>"info",
    size: <LoaderSize>"medium",
  }

  constructor() {}

}
